import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import * as constants from '../../../../../shared/constants';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCTabs from '../../../../../features/common/bscTabs/bscTabs';
import BSCTab from '../../../../../features/common/bscTab/bscTab';

const tabEnums = {
    add: {path: 'add', key: 0},
    edit: {path: 'edit', key: 1},
};

const useStyles = makeStyles((theme: Theme) => ({
    cap: {
        width: '100%',
        overflowX: 'auto',
    },
    tabWrapper: {
        marginBottom: theme.spacing(2),
    },
    tabWrapperMobile: {
        padding: '0 8px',
        marginBottom: theme.spacing(1),
    },
}));
interface SellYourCardTabsProps {
    listingsCount: number;
}

function SellYourCardTabs({listingsCount}: SellYourCardTabsProps) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {isTablet, isMobile} = useScreenSnap();
    const {selectedTab, cardId}: {selectedTab: string; cardId: string} = useParams();
    const activeTab = tabEnums?.[selectedTab.toLowerCase()]?.key ?? null;

    const handleTabChange = useCallback(
        (newTabKey: number) => {
            let newActiveTab = tabEnums.add.path;
            for (const tabKey in tabEnums) {
                const item = tabEnums[tabKey];
                newActiveTab = item.key === newTabKey ? item.path : newActiveTab;
            }
            dispatch({
                type: constants.SELLERS_LOCKER_CARDS_ACTIVE_TAB,
                payload: {sellersLockerCardsActiveTab: newTabKey},
            });
            history.replace(`/sellers/sell-your-card/${newActiveTab}/${cardId}`);
        },
        [history]
    );

    return (
        <div className={classes.cap}>
            <div className={isMobile || isTablet ? classes.tabWrapperMobile : classes.tabWrapper}>
                <BSCTabs activeTab={activeTab} onTabChange={handleTabChange}>
                    <BSCTab label="Sell your card" />
                    <BSCTab label={listingsCount > 0 ? `Current Listings (${listingsCount})` : 'Current Listings'} />
                </BSCTabs>
            </div>
        </div>
    );
}

export default SellYourCardTabs;
