/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import DeleteAccountModal from './DeleteAccountModal';
import {useAuthFunctions} from '../../../../../../../AuthContext';

const useStyles = makeStyles(theme => ({
    headerStyle: {
        fontWeight: 400,
    },
}));

function DeleteAccount() {
    const classes = useStyles();
    const authFunc = useAuthFunctions();
    const [confirmationOpen, toggleConfirmationOpen] = useState(false);
    const handleClose = c => {
        toggleConfirmationOpen(false);
        if (c === true) {
            authFunc.logout();
        }
    };

    return (
        <>
            <Grid container>
                <BSCButton fullWidth onClick={() => toggleConfirmationOpen(true)}>
                    Delete My Account
                </BSCButton>
            </Grid>
            <DeleteAccountModal open={confirmationOpen} onClose={c => handleClose(c)} />
        </>
    );
}

export default DeleteAccount;
