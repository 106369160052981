// import * as constants from '../../shared/constants';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../redux/reducers';
import BSCSearchField from '../common/bscSearchField/bscSearchField';

const useStyles = makeStyles((theme: Theme) => ({
    div: {
        flexGrow: 2,
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    popperItemWrap: {padding: theme.spacing(0, 0)},
    divider: {
        borderTop: '1px solid #D1D5DB',
    },
}));

function MarketplaceAsyncSearchInput() {
    const classes = useStyles();
    const history = useHistory();
    // const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [searchString, setSearchString] = useState('');
    const [sessionInStock, setSessionInStock] = useState(true);

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    const handleInputChange = e => {
        setSearchString(e.target.value);
    };

    const handleEnterDown = e => {
        // dispatch({
        //     type: constants.PRICE_RANGE_RESET,
        //     payload: {isPriceRangeReset: true},
        // });
        let sustainableSearchString = '';
        if (params && params.get('type')) {
            sustainableSearchString += `&type=${params.get('type')}`;
        }
        if (params && params.get('rawCondition[]')) {
            sustainableSearchString += `&rawCondition[]=${params.get('rawCondition[]')}`;
        }
        if (params && params.get('gradingCompany[]')) {
            sustainableSearchString += `&gradingCompany[]=${params.get('gradingCompany[]')}`;
        }
        if (params && params.get('gradeRangeStart')) {
            sustainableSearchString += `&gradeRangeStart=${params.get('gradeRangeStart')}`;
        }
        if (params && params.get('gradeRangeEnd')) {
            sustainableSearchString += `&gradeRangeEnd=${params.get('gradeRangeEnd')}`;
        }
        if (params && params.get('letterGrade[]')) {
            sustainableSearchString += `&letterGrade[]=${params.get('letterGrade[]')}`;
        }
        if (params && params.get('priceRangeStart')) {
            sustainableSearchString += `&priceRangeStart=${params.get('priceRangeStart')}`;
        }
        if (params && params.get('priceRangeEnd')) {
            sustainableSearchString += `&priceRangeEnd=${params.get('priceRangeEnd')}`;
        }
        if (params && params.get('sort')) {
            sustainableSearchString += `&sort=${params.get('sort')}`;
        }
        history.push(`../../../search?inStock=${sessionInStock}&${sustainableSearchString}&p=0&q=${encodeURIComponent(e)}`);
    };

    useEffect(() => {
        if (marketplaceState) {
            setSessionInStock(marketplaceState['sessionInStock']);
        }
    }, [marketplaceState]);

    useEffect(() => {
        if (params && params.get('q')) {
            setSearchString(params.get('q'));
        }
    }, []);

    return (
        <div className={classes.div}>
            <BSCSearchField
                placeholder={'Search'}
                value={searchString}
                onChange={handleInputChange}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onEnterDown={e => handleEnterDown(searchString)}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onClear={e => setSearchString('')}
                loading={false}
            />
        </div>
    );
}

export default MarketplaceAsyncSearchInput;
