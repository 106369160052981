import React, {useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import FooterClient from '../client/s3/footer/footerClient';
import BSCContainer from '../features/common/bscContainer/bscContainer';
import BSCTypography from '../features/common/bscTypography/bscTypography';
import {useQuery} from '../packages/core/src/hooks/useQuery';
import useScreenSnap from '../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../redux/reducers';
import * as constants from '../shared/constants';
import LinkGroup from './linkGroup';
import SignInConfirmationModal from './SignInConfirmationModal';
import SocialLinkIconGroup from './socialLinkIconGroup';
import {useAuthFunctions, useAuthState} from '../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../authConfig';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {SavedSearchControllerApi, OfferApi as CommonOfferApi} from '../client/openapitools/common/api';
import {OrderApi} from '../client/openapitools/seller/api';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        background: '#000000',
    },
    shop: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    sell: {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
    },
    linkWrapper: {
        padding: '5px',
    },
    link: {
        color: '#F5F8FA',
    },
    termsTitle: {
        color: theme.palette.common.white,
        fontSize: 10,
        fontStyle: 'unset',
        fontWeight: 'bold',
    },
    group: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fixedPWAFooter: {
        boxSizing: 'border-box',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 84,
        backgroundColor: '#fff',
        padding: 8,
        borderTop: '2px solid #d5d5d5',
        zIndex: 999,
    },
    navbarTab: {
        textAlign: 'center',
        flex: 1,
    },
    iconHeight: {
        height: 30,
    },
    navbarTextActive: {
        position: 'relative',
        top: -2,
        color: '#000',
        fontWeight: 700,
    },
    navbarTextInactive: {
        position: 'relative',
        top: -2,
        color: '#5f6368',
        fontWeight: 600,
    },
    badgeWrapper: {position: 'relative'},
    badge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        right: 12,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 8,
        height: 8,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    badgeTablet: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        right: 48,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 8,
        height: 8,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    badgeNumeral: {
        position: 'absolute',
        top: 5,
        zIndex: 10,
        color: '#fff',
        fontSize: 10,
        fontFamily: '"Poppins", sans-serif',
    },
}));

const defaultSellerOrderSearch = {
    name: '',
    orderNo: '',
    fromDate: null,
    toDate: null,
    page: 0,
    size: 100,
    status: [],
};

const BSCFooter = ({isMarketPlace}) => {
    const classes = useStyles();
    const {data} = useQuery(
        () => (isMarketPlace ? FooterClient.getMarketPlaceFooter() : FooterClient.getSellersLockerFooter()),
        [isMarketPlace]
    );
    const authFunc = useAuthFunctions();
    const {apiAuthConfig} = useAuthState();
    const {instance} = useMsal();
    const history = useHistory();
    const dispatch = useDispatch();
    const isCartActive = useRouteMatch('/cart');
    const isProductListing = useRouteMatch('/product');
    const isSellers = useRouteMatch('/sellers');
    const isSellYourCardsActive = useRouteMatch('/sellers/sell-your-card');
    const {isDesktop, isBelowDesktop, isTablet} = useScreenSnap();
    const [pwaOS, setPwaOS] = useState(null);
    const [modalOpen, toggleModalOpen] = useState(false);
    const [activeNavbarTab, setActiveNavbarTab] = useState(0);
    const [targetNavbarTab, setTargetNavbarTab] = useState(0);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    const navFromTap = targetTabIndex => {
        setTargetNavbarTab(targetTabIndex);
        switch (targetTabIndex) {
            case 0:
                history.push('/');
                break;
            case 1:
                if (localStorage.getItem('accessToken') !== null) {
                    history.push('/pwa/dashboard/buyer');
                } else {
                    history.push('/pwa/dashboard/account');
                }
                break;
            case 2:
                // if (localStorage.getItem('lastUserSearch') !== null) {
                //     const lastUserSearch = localStorage.getItem('lastUserSearch');
                //     localStorage.removeItem('lastUserSearch');
                //     if (lastUserSearch) {
                //         const trackSlashes = window.location.href.split('/').length - 3;
                //         switch (trackSlashes) {
                //             case 4:
                //                 history.push('../../../' + lastUserSearch);
                //                 break;
                //             case 3:
                //                 history.push('../../' + lastUserSearch);
                //                 break;
                //             case 2:
                //                 history.push('../' + lastUserSearch);
                //                 break;
                //             case 1:
                //                 history.push('./' + lastUserSearch);
                //                 break;
                //             default:
                //                 history.push(lastUserSearch);
                //                 break;
                //         }
                //     }
                // } else {
                history.push('/pwa/dashboard/search');
                // }
                break;
            case 3:
                if (localStorage.getItem('accessToken') === null) {
                    toggleModalOpen(true);
                } else {
                    history.push('/pwa/dashboard/notifications');
                }
                break;
            case 4:
                if (localStorage.getItem('accessToken') === null) {
                    toggleModalOpen(true);
                } else {
                    history.push('/sellers');
                }
                break;
            default:
                break;
        }
        if (targetTabIndex !== 3 && targetTabIndex !== 4) {
            dispatch({
                type: constants.PWA_NAVBAR_ACTIVE_TAB,
                payload: {
                    pwaNavbarActive: targetTabIndex,
                },
            });
        }
        dispatch({
            type: constants.SELLER_STOREFRONT_RESET,
            payload: {},
        });
        dispatch({
            type: constants.PWA_SELLER_STOREFRONT_FEEDBACK_RATING,
            payload: {pwaSellerStorefrontFeedbackRating: 0},
        });
        dispatch({
            type: constants.PWA_SELLER_STOREFRONT_CARDS_SOLD,
            payload: {pwaSellerStorefrontCardsSold: 0},
        });

        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 0,
            },
        });
    };

    const handleModalClose = e => {
        toggleModalOpen(false);
        if (e === 0) {
            if (targetNavbarTab === 3) {
                history.push('/pwa/dashboard/notifications');
                authFunc.loginPWA();
            }
            if (targetNavbarTab === 4) {
                history.push('/sellers');
                authFunc.loginPWA();
            }
        }
        if (e === 1) {
            if (targetNavbarTab === 3) {
                history.push('/pwa/dashboard/notifications');
                authFunc.signUpPWA();
            }
            if (targetNavbarTab === 4) {
                history.push('/sellers');
                authFunc.signUpPWA();
            }
        }
    };

    const fetchBuyerOffers = async () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const marketplaceOfferApi = new CommonOfferApi(apiConfig);
                            const offerResponse = await marketplaceOfferApi.getOfferHistory();
                            const outstandingOffersQty = offerResponse.data.history.filter(
                                offer => offer.offerStatus === 'Rejected' || (offer.offerStatus === 'Rejected' && offer.expiration !== '')
                            ).length;
                            if (outstandingOffersQty > 0) {
                                dispatch({
                                    type: constants.PWA_BUYER_OFFERS_COUNT,
                                    payload: {pwaBuyerOffersCount: outstandingOffersQty},
                                });
                            }
                        } catch (error) {
                            console.error('Error fetching offers', error);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.error('Error fetching offers', error);
                });
        }
    };

    const fetchSellerOrders = async searchObj => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const orderApi = new OrderApi(apiConfig);
                            const response = await orderApi.getOrderHistory(searchObj);
                            const unshippedCount = response.data.totalUnshipped;
                            if (unshippedCount > 0) {
                                dispatch({
                                    type: constants.PWA_SELLER_ORDERS_COUNT,
                                    payload: {pwaSellerOrdersCount: unshippedCount},
                                });
                            }
                        } catch (error) {
                            console.error('Error fetching orders', error);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.error('Error fetching orders', error);
                });
        }
    };

    const fetchSellerOffers = async () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const commonOfferApi = new CommonOfferApi(apiConfig);
                            const offerResponse = await commonOfferApi.getSellerOfferHistory();
                            const outstandingOffersQty = offerResponse.data.history.filter(
                                offer => offer.offerStatus !== 'Accepted' && offer.offerStatus !== 'Rejected'
                            ).length;
                            if (outstandingOffersQty > 0) {
                                dispatch({
                                    type: constants.PWA_SELLER_OFFERS_COUNT,
                                    payload: {pwaSellerOffersCount: outstandingOffersQty},
                                });
                            }
                        } catch (error) {
                            console.error('Error fetching offers', error);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.error('Error fetching offers', error);
                });
        }
    };

    const [buyerMessagesCount, setBuyerMessagesCount] = useState(0);
    const [sellerMessagesCount, setSellerMessagesCount] = useState(0);
    const [pwaFixedFooterHeight, setPwaFixedFooterHeight] = useState(84);
    const [isSmallDevice, setIsSmallDevice] = useState(false);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                if (marketplaceState.pwaSettings.pwaOS === 'iOS') {
                    setPwaFixedFooterHeight(84);
                } else {
                    setPwaFixedFooterHeight(84); // Android-specific later
                }
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
            setActiveNavbarTab(marketplaceState.pwaNavbarActive);
            const accountBadgeCount =
                marketplaceState.buyerMessagesCount + marketplaceState.pwaBuyerOffersCount + marketplaceState.pwaBuyerSavedSearchesCount;
            setBuyerMessagesCount(accountBadgeCount);
            const sellingBadgeCount =
                marketplaceState.sellerMessagesCount + marketplaceState.pwaSellerOffersCount + marketplaceState.pwaSellerOrdersCount;
            setSellerMessagesCount(sellingBadgeCount);
            setIsSmallDevice(marketplaceState.isSmallDevice);
        }
    }, [marketplaceState]);

    const [isPWAProductListing, setIsPWAProductListing] = useState(false);
    const [isPWACartActive, setIsPWAActiveCart] = useState(false);
    const [isPWASellYourCardsActive, setIsPWASellYourCardsActive] = useState(false);
    useEffect(() => {
        if (isProductListing && (pwaOS === 'iOS' || pwaOS === 'Android')) {
            setIsPWAProductListing(true);
        } else {
            setIsPWAProductListing(false);
        }
        if (isSellYourCardsActive && (pwaOS === 'iOS' || pwaOS === 'Android')) {
            setIsPWASellYourCardsActive(true);
        } else {
            setIsPWASellYourCardsActive(false);
        }
        if (isCartActive && (pwaOS === 'iOS' || pwaOS === 'Android')) {
            setIsPWAActiveCart(true);
        } else {
            setIsPWAActiveCart(false);
        }
    }, [isProductListing, pwaOS, isCartActive, isSellYourCardsActive]);

    const footerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const fetchData = async () => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                const savedSearchApi = new SavedSearchControllerApi(apiConfig);
                                const savedSearchResults = await savedSearchApi.getAllSavedSearchByUserId();
                                if (savedSearchResults.status === 200 && savedSearchResults.data?.length > 0) {
                                    const redDots = savedSearchResults.data.filter(search => search.changedSinceLastRun === true);
                                    if (redDots.length > 0) {
                                        dispatch({
                                            type: constants.PWA_BUYER_SAVED_SEARCHES_COUNT,
                                            payload: {pwaBuyerSavedSearchesCount: redDots.length},
                                        });
                                        setSavedSearchesCount(redDots.length.toString());
                                    }
                                }
                            } catch (error) {
                                /* empty */
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user', error);
                    });
            }
        };
        fetchData();
        fetchBuyerOffers();
        fetchSellerOrders(defaultSellerOrderSearch);
        fetchSellerOffers();
        setTimeout(() => {
            dispatch({
                type: constants.PWA_FOOTER_HEIGHT,
                payload: {pwaFooterHeight: footerRef.current.clientHeight === 0 ? pwaFixedFooterHeight : footerRef.current.clientHeight},
            });
        }, 1000);
    }, []);

    return (
        <div
            ref={footerRef}
            style={isPWAProductListing || isPWACartActive || isPWASellYourCardsActive ? {display: 'none'} : {display: 'block'}}
        >
            {(isTablet || isBelowDesktop) && (pwaOS === 'iOS' || pwaOS === 'Android') && (
                <div className={classes.fixedPWAFooter}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item md={2} className={classes.navbarTab} style={{paddingLeft: 0}} onClick={() => navFromTap(0)}>
                            {activeNavbarTab === 0 && <span className="material-icons">home</span>}
                            {activeNavbarTab !== 0 && <span className="material-icons-outlined">home</span>}
                            <BSCTypography
                                size10
                                label="Home"
                                className={activeNavbarTab === 0 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} onClick={() => navFromTap(1)}>
                            {buyerMessagesCount !== 0 && (
                                <div className={classes.badgeWrapper}>
                                    {!isTablet && (
                                        <span className={classes.badge}>
                                            <span className={classes.badgeNumeral}>{buyerMessagesCount}</span>
                                        </span>
                                    )}
                                    {isTablet && (
                                        <span className={classes.badgeTablet}>
                                            <span className={classes.badgeNumeral}>{buyerMessagesCount}</span>
                                        </span>
                                    )}
                                </div>
                            )}
                            {activeNavbarTab === 1 && <span className="material-icons">person</span>}
                            {activeNavbarTab !== 1 && <span className="material-icons-outlined">person</span>}
                            <BSCTypography size10 className={activeNavbarTab === 1 ? classes.navbarTextActive : classes.navbarTextInactive}>
                                Account
                            </BSCTypography>
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} onClick={() => navFromTap(2)}>
                            {activeNavbarTab === 2 && <span className="material-icons">search</span>}
                            {activeNavbarTab !== 2 && <span className="material-icons-outlined">search</span>}
                            <BSCTypography
                                size10
                                label="Search"
                                className={activeNavbarTab === 2 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} onClick={() => navFromTap(3)}>
                            {activeNavbarTab === 3 && <span className="material-icons">notifications</span>}
                            {activeNavbarTab !== 3 && <span className="material-icons-outlined">notifications</span>}
                            <BSCTypography
                                size10
                                label="Notifications"
                                className={activeNavbarTab === 3 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                        <Grid item md={2} className={classes.navbarTab} style={{paddingRight: 0}} onClick={() => navFromTap(4)}>
                            {sellerMessagesCount !== 0 && (
                                <div className={classes.badgeWrapper}>
                                    {!isTablet && (
                                        <span className={classes.badge}>
                                            <span className={classes.badgeNumeral}>{sellerMessagesCount}</span>
                                        </span>
                                    )}
                                    {isTablet && (
                                        <span className={classes.badgeTablet}>
                                            <span className={classes.badgeNumeral}>{sellerMessagesCount}</span>
                                        </span>
                                    )}
                                </div>
                            )}
                            {activeNavbarTab === 4 && <span className="material-icons">sell</span>}
                            {activeNavbarTab !== 4 && <span className="material-icons-outlined">sell</span>}
                            <BSCTypography
                                size10
                                label="Selling"
                                className={activeNavbarTab === 4 ? classes.navbarTextActive : classes.navbarTextInactive}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            {(isDesktop || (isBelowDesktop && pwaOS === null)) && (
                <div className={classes.root}>
                    <BSCContainer variant="page">
                        <Grid container spacing={4}>
                            {(data?.shop || data?.sell || data?.about || data?.help || data?.followus) && (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {data?.shop && (
                                            <Grid item className={classes.shop}>
                                                <LinkGroup title={data?.shop.title} links={data?.shop.links} col={6} />
                                            </Grid>
                                        )}
                                        {data?.sell && (
                                            <Grid item className={classes.sell}>
                                                <LinkGroup title={data?.sell.title} links={data?.sell.links} col={12} />
                                            </Grid>
                                        )}
                                        {data?.about && (
                                            <Grid item className={classes.sell}>
                                                <LinkGroup title={data?.about.title} links={data?.about.links} col={12} />
                                            </Grid>
                                        )}
                                        <Grid item className={classNames(classes.sell, classes.group)}>
                                            <Grid container spacing={2}>
                                                {data?.help && (
                                                    <Grid item xs={6} md={12}>
                                                        <LinkGroup title={data?.help.title} links={data?.help.links} col={12} />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {data?.followus && (
                                            <Grid item className={classes.sell}>
                                                <SocialLinkIconGroup title={data?.followus.title} links={data?.followus.links} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction={isDesktop ? 'row' : 'column-reverse'}
                                >
                                    {data?.terms && (
                                        <Grid item>
                                            <BSCTypography
                                                label={`©${new Date().getFullYear()} BuySportsCards.com Inc.`}
                                                className={classes.termsTitle}
                                            />
                                        </Grid>
                                    )}
                                    {data?.terms && (
                                        <Grid item style={{minWidth: 300}}>
                                            <Grid container spacing={2} direction="row">
                                                {data?.terms.links &&
                                                    data?.terms.links.map(link => {
                                                        return (
                                                            <Grid
                                                                item
                                                                xs={!isSellers ? (isDesktop ? 6 : 6) : isDesktop ? 4 : 4}
                                                                key={link.url}
                                                            >
                                                                <MenuItem
                                                                    className={classes.linkWrapper}
                                                                    component={Link}
                                                                    to={link.url}
                                                                    onClick={() => {
                                                                        window.scrollTo(0, 0);
                                                                    }}
                                                                    style={{justifyContent: 'center'}}
                                                                >
                                                                    <BSCTypography
                                                                        label={link.title}
                                                                        variant="subtitle1"
                                                                        className={classes.termsTitle}
                                                                    />
                                                                </MenuItem>
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </BSCContainer>
                </div>
            )}
            <SignInConfirmationModal open={modalOpen} onClose={e => handleModalClose(e)} isSmallDevice={isSmallDevice} />
        </div>
    );
};

export default BSCFooter;
