/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import * as constants from '../../../../../../shared/constants';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import Grid from '@material-ui/core/Grid';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {AUTH_REQUESTS} from '../../../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../../../AuthContext';
import useDateTimeNormalizer from '../../../../../../client/common/normalizer/dateTimeNormalizer';
import OfferHistoryNormalizer from '../../../../../../client/common/offer/history/offerHistoryNormalizer';
import {OfferApi, OfferHistory} from '../../../../../../client/openapitools/common/api';
import {OfferApi as MarketplaceOfferApi} from '../../../../../../client/openapitools/marketplace/api';
import BSCButton from '../../../../../../features/common/bscButton/bscButton';
import BSCCurrency from '../../../../../../features/common/bscCurrency/bscCurrency';
import BSCTypography from '../../../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../../../features/common/skeletons/BSCTableRowSkeleton';
import useReduxToast from '../../../../../../features/hooks/redux/toast/useReduxToast';
import {RootState} from '../../../../../../redux/reducers';
import MakeAnOfferModal from '../../../productPage/makeAnOfferModal';
import OrderCard from '../../components/orderCard';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'none',
        boxShadow: '0px 0px 4px #B8C2CC',
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    th: {
        color: '#41474C',
        textAlign: 'center',
        padding: theme.spacing(1),
        background: '#DAE0E6',
        borderBottom: 'none',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '4px 4px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    itemWrapper: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        border: '1.5px solid #dae0e5',
        textAlign: 'center',
        background: '#FFFFFF',
        '& p': {
            fontSize: 14,
        },
        '& button': {
            width: '75%',
            marginTop: '12px',
        },
        display: 'grid',
        alignItems: 'center',
    },
    td: {
        padding: theme.spacing(2),
    },
    status: {
        marginBottom: theme.spacing(1),
        textTransform: 'capitalize',
    },
    cardWrapper: {
        background: '#F5F8FA',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5),
            boxShadow: '0px 0px 4px #B8C2CC',
        },
    },
    statusBtn: {
        fontSize: theme.typography.subtitle1.fontSize,
    },
    offerHeader: {
        fontSize: 18,
        background: '#FFFFFF',
        lineHeight: '2.5',
    },
}));

const Offers = () => {
    const classes = useStyles();
    const history = useHistory();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const dispatch = useDispatch();
    const {apiAuthConfig} = useAuthState();
    const [makeAnOfferModalOpen, setMakeAnOfferModalOpen] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const normalizer = useDateTimeNormalizer();
    const {instance} = useMsal();
    const authFunc = useAuthFunctions();
    const {addToast} = useReduxToast();
    const YOUR_OFFERS_ARE_EMPTY = 'Your Offers are Empty';
    const [offers, setOffers] = useState([]);
    const handleMakeAnOffer = useCallback(
        item => {
            setSelectedCard(item);
            setMakeAnOfferModalOpen(true);
        },
        [setSelectedCard, setMakeAnOfferModalOpen]
    );
    const handleCancelOffer = useCallback(offer => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            setIsLoading(true);
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const marketplaceOfferApi = new MarketplaceOfferApi(apiConfig);
                            const response = await marketplaceOfferApi.updateOffer(
                                {offerId: offer.offerId, offerStatus: 'Cancel'},
                                null,
                                offer.offerId
                            );
                            if (response.status === 200) {
                                history.go(0);
                            }
                        } catch (error) {
                            console.error('Error fetching user', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'OfferHistory',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, []);

    const handleModalClose = useCallback(() => {
        setMakeAnOfferModalOpen(false);
    }, []);
    const showOrderDetail = orderId => {
        history.push({
            pathname: '/dashboard/orderHistory',
            state: {orderId: orderId},
        });
    };

    const [isLoading, setIsLoading] = useState(false);
    const [offerHistory, setOfferHistory] = useState([]);
    const [pwaOS, setPwaOS] = useState(null);

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
            if (marketplaceState.pwaBuyerOffersCount !== 0) {
                dispatch({
                    type: constants.PWA_BUYER_OFFERS_COUNT,
                    payload: {pwaBuyerOffersCount: 0},
                });
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            setIsLoading(true);
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const marketplaceOfferApi = new OfferApi(apiConfig);
                            const response = await marketplaceOfferApi.getOfferHistory();
                            setOfferHistory(response.data);
                            setIsLoading(false);
                            return response;
                        } catch (error) {
                            console.error('Error fetching user', error);
                            if (error?.response?.status === 404) {
                                setIsLoading(false);
                            } else {
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'OfferHistory',
                                });
                            }
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [apiAuthConfig]);

    useEffect(() => {
        if (offerHistory) {
            const offersData = OfferHistoryNormalizer.getOffers(offerHistory);
            setOffers(offersData);
        }
    }, [offerHistory]);

    return (
        <div className={classes.root}>
            {pwaOS === null && <BSCTypography className={classes.offerHeader} align="center" label="Your Offers" />}
            <div className={classes.th}>
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <BSCTypography label="cards" capitalize />
                    </Grid>
                    <Grid item xs={4}>
                        <BSCTypography label="offer details" capitalize />
                    </Grid>
                    <Grid item xs={4}>
                        <BSCTypography label="Status" capitalize />
                    </Grid>
                </Grid>
            </div>
            {!offers ||
                (isLoading && (
                    <Table>
                        <BSCTableRowSkeleton amountOfRows={2} amountOfCells={3} />
                    </Table>
                ))}
            {offers && offers?.length === 0 && (
                <div style={{padding: 32, textAlign: 'center'}}>
                    <BSCTypography style={{fontSize: 16}} label={YOUR_OFFERS_ARE_EMPTY} />
                </div>
            )}
            {!isLoading &&
                offers &&
                offers?.length > 0 &&
                offers?.map((offer: OfferHistory) => {
                    return (
                        <div key={offer.offerId} className={classes.cardWrapper}>
                            <Grid container>
                                <Grid item md={4} xs={12}>
                                    <div className={classes.itemWrapper}>
                                        <OrderCard card={offer.offerItem} flag hasOffer={true} />
                                    </div>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <div className={classNames(classes.itemWrapper, classes.td)}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Seller Name: " />
                                            </Grid>
                                            <Grid item>
                                                <BSCTypography
                                                    variant="body1"
                                                    label={
                                                        offer?.offerItem?.seller?.sellerProfile?.sellerStoreName ??
                                                        offer?.offerItem?.seller?.username
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Offer Date: " />
                                            </Grid>
                                            <Grid item>
                                                <BSCTypography variant="body1" label={normalizer.getDisplayDateTime(offer.date)} />
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Offer (each): " />
                                            </Grid>
                                            <Grid item>
                                                <BSCCurrency variant="body1" amount={offer.offerPrice} />
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <BSCTypography variant="body1" label="Quantity: " />
                                            </Grid>
                                            <Grid item>
                                                <BSCTypography variant="body1" label={offer.offerQuantity} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item md={4} xs={12} justifyContent="center">
                                    <div className={classNames(classes.itemWrapper, classes.td)}>
                                        <div>
                                            {offer.offerStatus &&
                                                offer.offerStatus.toLowerCase() === 'expired' &&
                                                offer.newOffer === true && (
                                                    <BSCButton
                                                        className={classes.statusBtn}
                                                        color="primary"
                                                        // eslint-disable-next-line prettier/prettier
                                                        onClick={() => handleMakeAnOffer(offer.offerItem)}
                                                    >
                                                        Make new offer
                                                    </BSCButton>
                                                )}
                                            {offer.offerStatus && offer.offerStatus.toLowerCase() !== 'new' && (
                                                <BSCTypography variant="body1" label={offer.offerStatus} className={classes.status} />
                                            )}
                                            {offer.offerStatus && offer.offerStatus.toLowerCase() === 'new' && (
                                                <>
                                                    <BSCButton
                                                        className={classes.statusBtn}
                                                        color="primary"
                                                        onClick={() => handleCancelOffer(offer)}
                                                    >
                                                        Cancel offer
                                                    </BSCButton>
                                                    <BSCTypography
                                                        variant="subtitle2"
                                                        label={`Time left: ${offer.expiration}`}
                                                        className={classes.status}
                                                    />
                                                </>
                                            )}
                                            {offer.offerStatus.toLowerCase() === 'accepted' && (
                                                <BSCButton
                                                    className={classes.statusBtn}
                                                    color="secondary"
                                                    onClick={() => showOrderDetail(offer.orderId)}
                                                >
                                                    Order details
                                                </BSCButton>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            <MakeAnOfferModal
                productInfo={selectedCard}
                cardListing={selectedCard?.card}
                open={makeAnOfferModalOpen}
                onModalClose={handleModalClose}
            />
        </div>
    );
};

export default Offers;
