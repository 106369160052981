/* eslint-disable @typescript-eslint/no-unused-vars */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {Container, Grid} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {Configuration, PaymentApi, PaymentOrder} from '../../../../client/openapitools/seller';
import BSCCurrency from '../../../../features/common/bscCurrency/bscCurrency';
import BscTableBlock, {BscTableBlockColumnDefinition} from '../../../../features/common/bscTableBlock/bscTableBlock';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';

const useStyles = makeStyles((theme: Theme) => ({
    rootPWA: {
        height: 'calc(100vh - 220px)',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
}));

const SellerPaymentDetails = () => {
    const classes = useStyles();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const params = useParams<{sellerPaymentId: string}>();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let paymentApi = new PaymentApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    // const {data: data, loading: loading} = useQuery(
    //     () => paymentApi.getPaymentOrders(null, params.sellerPaymentId),
    //     [params.sellerPaymentId]
    // );
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const stringRenderFunc = s => <BSCTypography label={s} />;
    const moneyRenderFunc = s => <BSCCurrency amount={s} />;
    const floatRenderFn = s =>
        parseFloat(s) > 0 ? (
            <BSCTypography style={{display: 'inline-block'}} label="-">
                <BSCCurrency style={{display: 'inline-block'}} amount={s} />
            </BSCTypography>
        ) : (
            <BSCCurrency amount={s} />
        );

    const columns = [
        {header: 'Order #', dataSelector: s => s.orderNo, renderFunc: stringRenderFunc},
        {header: 'Buyer', dataSelector: s => s.buyer, renderFunc: stringRenderFunc},
        {header: 'Total Sale', dataSelector: s => s.totalSale, renderFunc: moneyRenderFunc},
        {header: 'Payment Processing', dataSelector: s => s.paymentProcessingFee, renderFunc: floatRenderFn},
        {header: 'BSC Seller Fees', dataSelector: s => s.bscSellerFee, renderFunc: floatRenderFn},
        {header: 'BSC Shipping', dataSelector: s => s.bscShippingCharge, renderFunc: floatRenderFn},
        {header: 'Refunds', dataSelector: s => s.refundedOrders, renderFunc: floatRenderFn},
    ] as BscTableBlockColumnDefinition<PaymentOrder>[];

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            paymentApi = new PaymentApi(apiConfig);
                            const response = await paymentApi.getPaymentOrders(null, params.sellerPaymentId);
                            setLoading(false);
                            setData(response);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error fetching Seller payment details',
                                contextKey: 'Seller Payment Details',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center" spacing={2} style={{marginTop: '1rem'}}>
                {/* <Grid item style={{alignSelf: 'flex-start'}}>
                    <BSCBack />
                </Grid> */}
                <Grid item>
                    <BscTableBlock blockHeader={'Payments:'} loading={loading} data={data?.data} columns={columns}></BscTableBlock>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SellerPaymentDetails;
