import {ReactNode, forwardRef, ChangeEvent} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {useCallback} from 'react';
import {validatedSchema} from '../../../@types/validations/validations';
import {TextFieldProps} from 'material-ui';

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        background: theme.palette.common.white,
        border: '1px solid #DAE0E6',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        borderRadius: 4,
        textAlign: 'center',
        '&::after': {
            borderBottom: 'none',
        },
    },
    inputRoot: {
        fontStyle: 'none',
        lineHeight: 1.75,
        textTransform: 'none', // removes uppercase transformation
        [theme.breakpoints.up('md')]: {
            padding: '2px 8px',
            fontSize: '14px', //was 16px
        },
        [theme.breakpoints.down('sm')]: {
            padding: '2px 16px',
            fontSize: '14px', //was 16px
        },
        [theme.breakpoints.down('xs')]: {
            padding: '2px 16px',
            fontSize: '12px',
        },
    },
    removeAfterBorder: {
        '& .MuiFilledInput-underline:after': {
            borderBottom: 'none',
        },
    },
}));

interface BSCSelectProps extends TextFieldProps {
    children: ReactNode;
    onSortChange?: (item: string) => void;
    onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    selectedSortValue?: string;
    formStateValidations?: validatedSchema;
    inputKey?: string;
    placeholder?: null | string;
    value?: null | string | number;
    label?: null | string;
    error?: undefined | string | boolean;
    showArrow?: boolean;
}

const BSCSelect = forwardRef<HTMLDivElement, BSCSelectProps>(
    (
        {
            selectedSortValue,
            onSortChange,
            onInputChange,
            onOpenChange,
            onCloseChange,
            formStateValidations,
            children,
            inputKey = '',
            placeholder = null,
            value = null,
            label = null,
            error = undefined,
            showArrow = true,
            ...otherProps
        }: BSCSelectProps,
        ref
    ) => {
        const classes = useStyles();

        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                onSortChange(e.target.value);
            },
            [onSortChange]
        );

        const arrow = props =>
            showArrow && (
                <div style={{fontSize: '1.25rem'}}>
                    <KeyboardArrowDownIcon {...props} fontSize="inherit"></KeyboardArrowDownIcon>
                </div>
            );

        return (
            <>
                <TextField
                    {...otherProps}
                    ref={ref}
                    variant="filled"
                    select
                    InputProps={{
                        className: classes.input,
                        placeholder: formStateValidations?.[inputKey]?.label ?? placeholder,
                        name: inputKey || '',
                        error: formStateValidations?.[inputKey]?.errors
                            ? Boolean(formStateValidations?.[inputKey]?.errors)
                            : Boolean(error),
                        onChange: onInputChange ?? handleChange,
                    }}
                    SelectProps={{
                        IconComponent: arrow,
                        classes: {
                            root: classes.inputRoot,
                            select: classes.inputRoot,
                        },
                        displayEmpty: true,
                        label: formStateValidations?.[inputKey]?.errors ?? label,
                        onOpen: onOpenChange ?? (() => {}),
                        onClose: onCloseChange ?? (() => {}),
                    }}
                    value={formStateValidations?.[inputKey]?.value || selectedSortValue || value || ''}
                >
                    {children}
                </TextField>
            </>
        );
    }
);

export default BSCSelect;
