/* eslint-disable indent */
import * as constants from '../../../../shared/constants';
import {makeStyles, MobileStepper, Theme} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {memo, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import Normalizer from '../../../../client/common/normalizer/normalizer';
import {Card} from '../../../../client/openapitools/marketplace';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../redux/reducers';
import ProductInfoModal from './ProductInfoModal';
import './productPage.scss';
import ProductPageMobileNav from './productPageMobileNav';

const baseStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    rootMobile: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '8px',
    },
    relativeNav: {
        position: 'relative',
    },
    stickyNav: {
        position: 'static',
        background: 'white',
        //  zIndex: 8,
        //  top: '0px',

        [theme.breakpoints.down('xs')]: {
            position: 'sticky',
            zIndex: 8,
            top: '0px',
        },
    },
    box: {
        background: theme.palette.common.white,
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: 4,
        paddingTop: theme.spacing(2),
    },
    buttonWrap: {
        paddingTop: 0,
    },
    heading: {
        padding: theme.spacing(0, 2),
        fontSize: '18px',
        fontWeight: 600,
    },
    headingMobile: {
        padding: theme.spacing(0, 2),
        fontSize: '16px',
        fontWeight: 600,
    },
    img: {
        width: 266,
        height: 266,
        objectFit: 'contain',
        margin: theme.spacing(0, 'auto'),
        display: 'block',
        willChange: 'filter',
        filter: 'drop-shadow(0px 2.7409px 2.7409px rgba(0, 0, 0, 0.25))',
    },
    imgMobile: {
        height: 'calc(100vh * .25)',
        objectFit: 'contain',
        margin: theme.spacing(0, 'auto'),
        willChange: 'filter',
        display: 'block',
        filter: 'drop-shadow(0px 2.7409px 2.7409px rgba(0, 0, 0, 0.25))',
    },
    cardFeedSider: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
        width: '58%',
    },
    dotActive: {
        background: theme.palette.primary.main,
    },
    stepper: {
        bottom: '0px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        zIndex: 0,
        justifyContent: 'center',
        paddingBottom: '16px',
    },
    stepperMobile: {
        bottom: '0px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        justifyContent: 'center',
        padding: '8px 0px',
    },
    divider: {
        margin: '0px 0px 8px',
    },
    chip: {
        margin: 'auto',
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        bottom: 16,
    },
}));

interface ProductInfoHeaderProps {
    productInfoData: null | Card;
    loading: boolean;
}

function ProductInfoHeader({productInfoData, loading}: ProductInfoHeaderProps) {
    const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {isDesktop, isBelowTablet, isMobile} = useScreenSnap();
    const classes = baseStyles();
    const cardNormalizer = useCardNormalizer();
    const [activeStep, setActiveStep] = useState(0);
    const [isProductImageFrontView, setProductImageFrontView] = useState(true);
    const [featuredImageFront, setFeaturedImageFront] = useState(marketplaceState.productImageFront);
    const [featuredImageBack, setFeaturedImageBack] = useState(marketplaceState.productImageBack);
    const flipCard = () => {
        if (isProductImageFrontView) {
            setProductImageFrontView(false);
            setActiveStep(1);
        } else {
            setProductImageFrontView(true);
            setActiveStep(0);
        }
    };
    const [modalOpen, toggleModalOpen] = useState(false);

    const handleClose = () => {
        toggleModalOpen(false);
    };

    const [isHovering, setHovering] = useState(false);
    const startHover = () => {
        setHovering(true);
    };
    const stopHover = () => {
        setHovering(false);
    };

    const [isStockImageFront, setStockImageFront] = useState(false);
    const [isStockImageBack, setStockImageBack] = useState(false);
    const [ribbonSellerName, setRibbonSellerName] = useState(null);

    const headerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.sellerImageFront.includes(marketplaceState.stockImageFront)) {
                setStockImageFront(true);
            } else {
                setStockImageFront(false);
            }
            if (marketplaceState.sellerImageBack.includes(marketplaceState.stockImageBack)) {
                setStockImageBack(true);
            } else {
                setStockImageBack(false);
            }
            if (marketplaceState.isSellerSelected) {
                setFeaturedImageFront(marketplaceState.sellerImageFront);
                setFeaturedImageBack(marketplaceState.sellerImageBack);
            } else {
                setFeaturedImageFront(Normalizer.getS3ImagePath(productInfoData.stockImgFront));
                setFeaturedImageBack(Normalizer.getS3ImagePath(productInfoData.stockImgBack));
            }
            if (marketplaceState.sellerName?.length > 16) {
                setRibbonSellerName(marketplaceState.sellerName.slice(0, 16) + '...');
            } else {
                setRibbonSellerName(marketplaceState.sellerName);
            }
            if (marketplaceState.pwaProductInfoHeaderHeight !== headerRef.current?.clientHeight) {
                setTimeout(() => {
                    dispatch({
                        type: constants.PWA_PRODUCT_INFO_HEADER_HEIGHT,
                        payload: {pwaProductInfoHeaderHeight: headerRef.current?.clientHeight},
                    });
                }, 1000);
            }
        }
    }, [marketplaceState, headerRef.current?.clientHeight]);

    return (
        <>
            <div ref={headerRef} className={isBelowTablet || isMobile ? classes.stickyNav : classes.relativeNav}>
                {isProductImageFrontView && !isStockImageFront && marketplaceState.sellerName.length > 0 ? (
                    <div
                        className={`${
                            isBelowTablet || isMobile
                                ? isMobile
                                    ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                    : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-top-left'
                        }`}
                    >
                        <span>
                            Viewing <p style={{margin: '4px 0px 0px', textOverflow: 'ellipsis'}}>{ribbonSellerName}</p>
                        </span>
                    </div>
                ) : null}

                {!isProductImageFrontView && !isStockImageBack && marketplaceState.sellerName.length > 0 ? (
                    <div
                        className={`${
                            isBelowTablet || isMobile
                                ? isMobile
                                    ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                    : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-top-left'
                        }`}
                    >
                        <span>
                            Viewing <p style={{margin: '4px 0px 0px', textOverflow: 'ellipsis'}}>{ribbonSellerName}</p>
                        </span>
                    </div>
                ) : null}

                {isProductImageFrontView && isStockImageFront && marketplaceState.sellerName ? (
                    <div
                        className={`${
                            isBelowTablet || isMobile
                                ? isMobile
                                    ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                    : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-top-left'
                        }`}
                    >
                        <span style={{backgroundColor: '#EF2424'}}>
                            <p style={{margin: '4px 8px 4px 0'}}>No Seller Image</p>
                        </span>
                    </div>
                ) : null}

                {!isProductImageFrontView && isStockImageBack && marketplaceState.sellerName ? (
                    <div
                        className={`${
                            isBelowTablet || isMobile
                                ? isMobile
                                    ? 'ribbon ribbon-mobile ribbon-top-left ribbon-top-left-mobile'
                                    : 'ribbon ribbon-mobile-landscape ribbon-top-left ribbon-top-left-mobile'
                                : 'ribbon ribbon-top-left'
                        }`}
                    >
                        <span style={{backgroundColor: '#EF2424'}}>
                            <p style={{margin: '4px 8px 4px 0'}}>No Seller Image</p>
                        </span>
                    </div>
                ) : null}

                <div style={{position: 'relative', padding: '16px 0 0 0'}}>
                    <BSCImage
                        alt={`${productInfoData?.setName} ${productInfoData?.variantName} ${productInfoData?.players} #${productInfoData?.cardNo}`}
                        className={isBelowTablet || isMobile ? classes.imgMobile : classes.img}
                        height={isBelowTablet || isMobile ? '226px' : '266px'}
                        width={isBelowTablet || isMobile ? '226px' : '266px'}
                        src={isProductImageFrontView ? featuredImageFront : featuredImageBack}
                        title={`${productInfoData?.players} #${productInfoData?.cardNo}`}
                        loading="eager"
                        onMouseEnter={startHover}
                        onMouseLeave={stopHover}
                        onClick={
                            isMobile
                                ? () => {
                                      setHovering(false);
                                      toggleModalOpen(true);
                                  }
                                : null
                        }
                    />
                    {isDesktop && (
                        <div style={isHovering ? {display: 'block'} : {display: 'none'}} className={classes.chip}>
                            <Chip
                                label="Click to Expand"
                                onMouseEnter={startHover}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                onClick={e => {
                                    setHovering(false);
                                    toggleModalOpen(true);
                                }}
                            />
                        </div>
                    )}
                </div>
                <MobileStepper
                    classes={{dotActive: classes.dotActive}}
                    className={isBelowTablet || isMobile ? classes.stepperMobile : classes.stepper}
                    activeStep={activeStep}
                    onClick={() => flipCard()}
                    steps={2}
                    variant="dots"
                />
                {marketplaceState.pwaSettings.pwaOS === '' && (
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                        <BSCTypography
                            variant="body2"
                            align="center"
                            semibold
                            className={isBelowTablet || isMobile ? classes.headingMobile : classes.heading}
                            label={loading ? null : cardNormalizer.getPlayerName(productInfoData)}
                            // style={
                            //     isMobile
                            //         ? {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 350, lineHeight: 'inherit'}
                            //         : {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 420, lineHeight: 'inherit'}
                            // }
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                                padding: 0,
                                lineHeight: 'inherit',
                            }}
                        />
                    </div>
                )}
                <BSCDivider className={isBelowTablet || isMobile ? classes.divider : null} />
                {(isBelowTablet || isMobile) && (
                    <>
                        <ProductPageMobileNav />
                    </>
                )}
            </div>
            <ProductInfoModal
                open={modalOpen}
                enlargedImgFront={featuredImageFront}
                enlargedImgBack={featuredImageBack}
                isStockImageFront={isStockImageFront}
                isStockImageBack={isStockImageBack}
                marketplaceStateSellerName={marketplaceState.sellerName}
                ribbonSellerName={ribbonSellerName}
                onClose={handleClose}
            />
        </>
    );
}

export default memo(ProductInfoHeader);
