/* eslint-disable @typescript-eslint/no-unused-vars */
import {Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {MoreHoriz} from '@material-ui/icons';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import {RootState} from '../../../../../redux/reducers';
import * as constants from '../../../../../shared/constants';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
    listWrapperiOS: {
        height: 'calc(100vh - 285px)',
        overflowX: 'hidden',
        overflowY: 'scroll',
        padding: '8px 0 8px 6px !important',
    },
    listWrapperAndroid: {
        height: 'calc(100vh - 215px)',
        overflowX: 'hidden',
        overflowY: 'scroll',
        padding: '8px 0 8px 6px !important',
    },
    listWrapperContainer: {
        minHeight: 'calc(100vh - 300px)',
    },
    itemWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        marginBottom: 6,
    },
    deleteImage: {
        width: 28,
        marginLeft: '30%',
        marginTop: 6,
    },
    sellerIcon: {
        width: 24,
        height: 24,
        objectFit: 'cover',
        borderRadius: '50%',
    },
    listingsRecentSection: {
        maxHeight: 550,
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    listingsSavedSection: {
        maxHeight: 475,
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    listingsRecentSectionMax: {
        maxHeight: 550,
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    listingsSavedSectionMax: {
        maxHeight: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    cardChangeWrapper: {position: 'relative'},
    cardChange: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
}));

interface SearchDashboardListProps {
    view: number;
    index: number;
    listings: [];
    sellers: [];
    handleTrashListing: (id: string) => void;
    handleTrashSeller: (id: string) => void;
    clearAllRecent?: () => void;
}

function SearchDashboardList({view, listings, sellers, handleTrashListing, handleTrashSeller, clearAllRecent}: SearchDashboardListProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [swipedRow, setSwipedRow] = useState(-1);
    const [swipedSellerRow, setSwipedSellerRow] = useState(-1);
    const [searchListings, setSearchListings] = useState([]);
    const [isShowAllRecent, setIsShowAllRecent] = useState(false);
    const [isShowAllSaved, setIsShowAllSaved] = useState(false);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const handleSellerStorefrontSelected = (id, name) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('sellerId', id);
        searchParams.set('sellerName', name);
        window.location.search = searchParams.toString();
    };

    const [redDotsTotal, setRedDotsTotal] = useState(0);
    const hideCardChangeIcon = ndx => {
        const redDotIndicator = document.getElementById('cardChangeIcon' + ndx) as HTMLElement;
        if (redDotIndicator !== null) {
            redDotIndicator.style.visibility = 'hidden';
            dispatch({
                type: constants.PWA_BUYER_SAVED_SEARCHES_COUNT,
                payload: {pwaBuyerSavedSearchesCount: redDotsTotal - 1},
            });
        }
    };

    const resetElementCTAs = () => {
        if (swipedRow !== -1 || swipedSellerRow !== -1) {
            setSwipedRow(-1);
            setSwipedSellerRow(-1);
        }
    };

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        if (listings) {
            const tempSearchListings = [];
            for (const listing of listings) {
                if (listing.criteria !== null) {
                    let secondaryLine = '';
                    let urlSearchString;
                    if (view === 0) {
                        urlSearchString = listing.criteria.query;
                    } else {
                        urlSearchString = listing.queryStr;
                    }
                    const params = new URLSearchParams(urlSearchString);
                    const searchListing = {};
                    if (view === 1) {
                        searchListing['id'] = listing.id;
                    }

                    if (params.get('q')) {
                        searchListing['searchPrimary'] = params.get('q');
                    } else {
                        if (params.get('sellerName')) {
                            searchListing['searchPrimary'] = params.get('sellerName');
                        } else {
                            if (urlSearchString) {
                                if (urlSearchString.includes('players')) {
                                    const deocdedURI = decodeURI(urlSearchString);
                                    const uriParam = new URLSearchParams(deocdedURI);
                                    searchListing['searchPrimary'] = uriParam.get('players[]');
                                }
                                if (urlSearchString.includes('setName')) {
                                    const deocdedURI = decodeURI(urlSearchString);
                                    const uriParam = new URLSearchParams(deocdedURI);
                                    searchListing['searchPrimary'] = uriParam.get('setName[]');
                                }
                                if (urlSearchString.includes('variant')) {
                                    const deocdedURI = decodeURI(urlSearchString);
                                    const uriParam = new URLSearchParams(deocdedURI);
                                    searchListing['searchPrimary'] = uriParam.get('variant[]');
                                }
                                if (urlSearchString.includes('variantName')) {
                                    const deocdedURI = decodeURI(urlSearchString);
                                    const uriParam = new URLSearchParams(deocdedURI);
                                    searchListing['searchPrimary'] = uriParam.get('variantName[]');
                                }
                            }
                        }
                    }

                    const isInStock = params.get('inStock');
                    if (listing.criteria.productCriteria.priceRangeEnd === 5 && listing.criteria.productCriteria.priceRangeStart === null) {
                        secondaryLine += ' Under $5';
                    }
                    if (listing.criteria.productCriteria.priceRangeStart === 5 && listing.criteria.productCriteria.priceRangeEnd === 20) {
                        secondaryLine += ' $5 to $20';
                    }
                    if (
                        listing.criteria.productCriteria.priceRangeStart === 20 &&
                        listing.criteria.productCriteria.priceRangeEnd === null
                    ) {
                        secondaryLine += ' Over $20';
                    }
                    if (listing.criteria.filters?.sport?.length > 0) {
                        for (const sport of listing.criteria.filters.sport) {
                            secondaryLine += ' ' + sport;
                        }
                    }
                    if (listing.criteria.filters?.year?.length > 0) {
                        for (const year of listing.criteria.filters.year) {
                            secondaryLine += ' ' + year;
                        }
                    }
                    if (listing.criteria.filters?.setName?.length > 0) {
                        for (const setName of listing.criteria.filters.setName) {
                            secondaryLine += ' ' + setName;
                        }
                    }
                    if (listing.criteria.filters?.variant?.length > 0) {
                        for (const variant of listing.criteria.filters.variant) {
                            secondaryLine += ' ' + variant;
                        }
                    }
                    if (listing.criteria.filters?.variantName?.length > 0) {
                        for (const variantName of listing.criteria.filters.variantName) {
                            secondaryLine += ' ' + variantName;
                        }
                    }
                    if (listing.criteria.filters?.teamName?.length > 0) {
                        for (const teamName of listing.criteria.filters.teamName) {
                            secondaryLine += ' ' + teamName;
                        }
                    }
                    if (listing.criteria.filters?.cardNo?.length > 0) {
                        for (const cardNo of listing.criteria.filters.cardNo) {
                            secondaryLine += ' #' + cardNo;
                        }
                    }
                    let conditionsCount = 0;
                    if (listing.criteria?.productCriteria?.condition?.raw?.length > 0) {
                        conditionsCount += listing.criteria.productCriteria.condition.raw.length;
                    }
                    if (listing.criteria?.productCriteria?.condition?.graded?.company?.length > 0) {
                        conditionsCount += listing.criteria.productCriteria.condition.graded.company.length;
                    }
                    if (conditionsCount === 1) {
                        secondaryLine += ' 1 Condition ';
                    }
                    if (conditionsCount > 1) {
                        secondaryLine += ' ' + conditionsCount + ' Conditions ';
                    }
                    if (searchListing['searchPrimary']) {
                        const cleanPrimaryLine = searchListing['searchPrimary'].trim();
                        const noDashPrimaryLine = cleanPrimaryLine.replace(/-/g, ' ');
                        searchListing['searchPrimary'] = noDashPrimaryLine;
                    }
                    if (secondaryLine) {
                        const cleanSecondaryLine = secondaryLine.trim();
                        if (cleanSecondaryLine !== '') {
                            const noDashSecondaryLine = cleanSecondaryLine.replace(/-/g, ' ');
                            searchListing['searchSecondary'] = 'Filters: ' + noDashSecondaryLine;
                        }
                    }
                    if (listing.createdDateTime !== null) {
                        searchListing['createdDateTime'] = new Date(listing.createdDateTime).getTime();
                    }
                    if (view === 0) {
                        searchListing['searchQuery'] = listing.criteria.query;
                    } else {
                        if (listing.queryStr?.includes('sellerId')) {
                            searchListing['searchQuery'] = listing.queryStr + '&savedSellerSearchId=' + listing.id;
                        } else {
                            searchListing['searchQuery'] = listing.queryStr + '&savedSearchId=' + listing.id + '&sort=recently-added';
                        }
                    }
                    if (listing.changedSinceLastRun === true) {
                        setRedDotsTotal(prevState => prevState + 1);
                    }
                    searchListing['changedSinceLastRun'] = listing.changedSinceLastRun;
                    tempSearchListings.push(searchListing);
                }
            }

            if (view === 0) {
                const sortedRecentListings = tempSearchListings.sort((a, b) => {
                    if (a.createdDateTime > b.createdDateTime) return -1;
                    else if (a.createdDateTime < b.createdDateTime) return 1;
                    else return 0;
                });
                const uniqueArray = [];
                for (let x = 0; x < sortedRecentListings.length; x++) {
                    if (sortedRecentListings[x]['searchPrimary']) {
                        if (uniqueArray.length === 0) {
                            sortedRecentListings[x]['index'] = x;
                            sortedRecentListings[x]['isSwiped'] = false;
                            delete sortedRecentListings[x]['createdDateTime'];
                            delete sortedRecentListings[x]['changedSinceLastRun'];
                            uniqueArray.push(sortedRecentListings[x]);
                        } else {
                            if (uniqueArray.length < 10) {
                                if (sortedRecentListings[x]['searchPrimary'] !== uniqueArray[uniqueArray.length - 1]['searchPrimary']) {
                                    sortedRecentListings[x]['index'] = x;
                                    sortedRecentListings[x]['isSwiped'] = false;
                                    delete sortedRecentListings[x]['createdDateTime'];
                                    delete sortedRecentListings[x]['changedSinceLastRun'];
                                    uniqueArray.push(sortedRecentListings[x]);
                                } else {
                                    if (
                                        sortedRecentListings[x]['searchSecondary'] !==
                                        uniqueArray[uniqueArray.length - 1]['searchSecondary']
                                    ) {
                                        sortedRecentListings[x]['index'] = x;
                                        sortedRecentListings[x]['isSwiped'] = false;
                                        delete sortedRecentListings[x]['createdDateTime'];
                                        delete sortedRecentListings[x]['changedSinceLastRun'];
                                        uniqueArray.push(sortedRecentListings[x]);
                                    }
                                }
                            }
                        }
                    }
                }
                for (let x = 0; x < uniqueArray.length; x++) {
                    if (uniqueArray[x]['searchQuery'].includes('sellerName')) {
                        uniqueArray[x]['searchPrimary'] = 'Storefront: ' + uniqueArray[x]['searchPrimary'];
                    }
                }
                setSearchListings(uniqueArray);
            } else {
                if (tempSearchListings && tempSearchListings.length > 0) {
                    const sortedSavedListings = tempSearchListings.sort((a, b) => {
                        if (a.changedSinceLastRun > b.changedSinceLastRun) return -1;
                        else if (a.changedSinceLastRun < b.changedSinceLastRun) return 1;
                        else return 0;
                    });
                    setSearchListings(sortedSavedListings);
                }
            }
        }
    }, [listings]);

    useEffect(() => {
        localStorage.removeItem('infiniteScrollingPageNumber');
        localStorage.removeItem('scrollableId');
    }, []);

    return (
        <div className={pwaOS === 'iOS' ? classes.listWrapperiOS : classes.listWrapperAndroid}>
            <Grid container className={classes.listWrapperContainer} onClick={resetElementCTAs}>
                <Grid item xs={12}>
                    <Grid container direction="row">
                        {view === 1 && (
                            <Grid item xs={12}>
                                <BSCTypography size16 underline style={{fontWeight: 700}}>
                                    Searches:
                                </BSCTypography>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            container
                            className={classNames({
                                [classes.listingsRecentSection]: view === 0 && !isShowAllRecent,
                                [classes.listingsRecentSectionMax]: view === 0 && isShowAllRecent,
                                [classes.listingsSavedSection]: view === 1 && !isShowAllSaved,
                                [classes.listingsSavedSectionMax]: view === 1 && isShowAllSaved,
                            })}
                        >
                            {searchListings.map((searchItem, index) => (
                                <Grid item key={index} id={'' + index} xs={12} style={{paddingTop: 16}}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={view === 0 ? 12 : 10}>
                                            <Link
                                                to={'../../../search' + searchItem.searchQuery}
                                                onClick={() => hideCardChangeIcon(index)}
                                                style={{textDecoration: 'none'}}
                                            >
                                                {/* {view === 0 && searchItem.changedSinceLastRun && (
                                                    <div className={classes.cardChangeWrapper}>
                                                        <span className={classes.cardChange} />
                                                    </div>
                                                )} */}
                                                {view === 1 && searchItem.changedSinceLastRun && (
                                                    <div id={'cardChangeIcon' + index} className={classes.cardChangeWrapper}>
                                                        <span className={classes.cardChange} />
                                                    </div>
                                                )}
                                                <BSCTypography
                                                    id={index + 'p'}
                                                    size16
                                                    capitalize
                                                    style={
                                                        view === 1
                                                            ? {
                                                                  fontWeight: 400,
                                                                  color: '#000',
                                                                  whiteSpace: 'nowrap',
                                                                  overflow: 'hidden',
                                                                  textOverflow: 'ellipsis',
                                                                  width: 350,
                                                                  marginLeft: 32,
                                                              }
                                                            : {
                                                                  fontWeight: 400,
                                                                  color: '#000',
                                                                  whiteSpace: 'nowrap',
                                                                  overflow: 'hidden',
                                                                  textOverflow: 'ellipsis',
                                                                  width: 350,
                                                              }
                                                    }
                                                >
                                                    {searchItem.searchPrimary}
                                                </BSCTypography>
                                                {searchItem.searchSecondary && (
                                                    <BSCTypography
                                                        id={index + 's'}
                                                        style={
                                                            view === 1
                                                                ? {
                                                                      textTransform: 'capitalize',
                                                                      color: 'rgba(0, 0, 0, 0.54)',
                                                                      whiteSpace: 'nowrap',
                                                                      overflow: 'hidden',
                                                                      textOverflow: 'ellipsis',
                                                                      width: 350,
                                                                      marginLeft: 32,
                                                                  }
                                                                : {
                                                                      textTransform: 'capitalize',
                                                                      color: 'rgba(0, 0, 0, 0.54)',
                                                                      whiteSpace: 'nowrap',
                                                                      overflow: 'hidden',
                                                                      textOverflow: 'ellipsis',
                                                                      width: 350,
                                                                  }
                                                        }
                                                    >
                                                        {searchItem.searchSecondary}
                                                    </BSCTypography>
                                                )}
                                            </Link>
                                        </Grid>
                                        {view === 1 && (
                                            <Grid
                                                item
                                                xs={2}
                                                style={swipedRow === index ? {backgroundColor: '#f00', height: 40} : {height: 40}}
                                            >
                                                {swipedRow !== index && (
                                                    <IconButton style={{padding: '2px 16px'}} onClick={() => setSwipedRow(index)}>
                                                        <MoreHoriz htmlColor="black" fontSize={'large'} />
                                                    </IconButton>
                                                )}
                                                {swipedRow === index && (
                                                    <BSCImage
                                                        src="/img/delete.png"
                                                        className={classes.deleteImage}
                                                        onClick={() => handleTrashListing(searchItem.id)}
                                                    />
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: 4}}>
                            {view === 0 && searchListings?.length > 0 && (
                                <Grid item xs={12}>
                                    <Grid item xs={12} style={{paddingTop: 16}}>
                                        <BSCTypography size14 color="primary" onClick={clearAllRecent}>
                                            Clear All
                                        </BSCTypography>
                                    </Grid>
                                </Grid>
                            )}
                            {view === 1 && (searchListings?.length > 0 || sellers?.length > 0) && (
                                <Grid item xs={12}>
                                    <Grid item xs={12} style={isShowAllSaved ? {display: 'none'} : {}}>
                                        {searchListings?.length > 8 && (
                                            <BSCTypography size14 color="primary" onClick={() => setIsShowAllSaved(true)}>
                                                See More
                                            </BSCTypography>
                                        )}
                                        {searchListings?.length <= 8 && <>&nbsp;</>}
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop: 32}}>
                                        <BSCTypography size16 underline style={{fontWeight: 700}}>
                                            Sellers:
                                        </BSCTypography>
                                    </Grid>
                                    <Grid item xs={12} container className={classes.sellersSection}>
                                        {sellers.map((sellerItem, index) => (
                                            <Grid item key={index} id={'' + index} xs={12} style={{paddingTop: 8}}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item container xs={view === 0 ? 12 : 10}>
                                                        <Grid item xs={1}>
                                                            <BSCImage
                                                                src={
                                                                    !sellerItem['sellerIconUrl'].includes('null')
                                                                        ? sellerItem['sellerIconUrl']
                                                                        : '/img/Seller-Default.png'
                                                                }
                                                                title={sellerItem['sellerName']}
                                                                className={classes.sellerIcon}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Link
                                                                to={
                                                                    '../../../search?inStock=true&sellerId=' +
                                                                    sellerItem['sellerId'] +
                                                                    '&sellerName=' +
                                                                    encodeURIComponent(sellerItem['sellerName'])
                                                                }
                                                                style={{textDecoration: 'none'}}
                                                            >
                                                                <BSCTypography
                                                                    id={index.toString()}
                                                                    size16
                                                                    capitalize
                                                                    style={{fontWeight: 400, color: '#000', paddingLeft: 4}}
                                                                >
                                                                    {sellerItem['sellerName']}
                                                                </BSCTypography>
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                    {view === 1 && (
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            style={
                                                                swipedSellerRow === index
                                                                    ? {backgroundColor: '#f00', height: 40}
                                                                    : {height: 40}
                                                            }
                                                        >
                                                            {swipedSellerRow !== index && (
                                                                <IconButton
                                                                    style={{padding: '2px 16px'}}
                                                                    onClick={() => setSwipedSellerRow(index)}
                                                                >
                                                                    <MoreHoriz htmlColor="black" fontSize={'large'} />
                                                                </IconButton>
                                                            )}
                                                            {swipedSellerRow === index && (
                                                                <BSCImage
                                                                    src="/img/delete.png"
                                                                    className={classes.deleteImage}
                                                                    onClick={() => handleTrashSeller(sellerItem['id'])}
                                                                />
                                                            )}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default SearchDashboardList;
