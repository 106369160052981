import {makeStyles, Grid, DialogTitle, Dialog, DialogActions, Theme} from '@material-ui/core';
import React from 'react';
import BSCButton from '../features/common/bscButton/bscButton';
import BSCTypography from '../features/common/bscTypography/bscTypography';
import BscToggledEditButton from '../features/common/bscEditIcon/bscEditIcon';

const useStyles = makeStyles((theme: Theme) => ({
    fullWidth: {
        width: '100%',
    },
    pwaPaper: {
        width: '98vw',
        margin: 0,
    },
    btnStyles: {
        marginTop: 10,
        fontSize: '14px',
    },
    borderButton: {
        border: '2px solid rgba(0, 0, 0, 0.54)',
        borderRadius: 15,
    },
    dialogTitle: {
        '& h2': {
            fontSize: '1.25rem',
        },
        padding: '16px 8px',
    },
    dialogButtons: {
        padding: '0px 10px 12px 10px',
        justifyContent: 'center',
    },
    alert: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));

interface SignInConfirmationModalProps {
    open: boolean;
    onClose: (value: number) => void;
    isSmallDevice: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SignInConfirmationModal({open, onClose, isSmallDevice}: SignInConfirmationModalProps) {
    const classes = useStyles();
    const handleSignInClose = () => {
        onClose(0);
    };
    const handleCreateAccountClose = () => {
        onClose(1);
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth PaperProps={isSmallDevice ? {className: classes.pwaPaper} : {}}>
            <DialogTitle className={classes.dialogTitle}>
                <Grid container>
                    <Grid item>
                        <BscToggledEditButton isEdit={true} onCLick={() => onClose(-1)} />
                    </Grid>
                    <Grid item style={{flex: 1, paddingLeft: 4}}>
                        <BSCTypography label="Account Required to Continue" variant="body2" />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogActions className={classes.dialogButtons}>
                <BSCButton fullWidth color="secondary" onClick={handleSignInClose}>
                    <BSCTypography label="Sign In" />
                </BSCButton>
                <BSCButton fullWidth color="primary" onClick={handleCreateAccountClose}>
                    <BSCTypography label="Create Account" />
                </BSCButton>
            </DialogActions>
        </Dialog>
    );
}

export default SignInConfirmationModal;
