import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Configuration} from '../../client/openapitools/common';
import {CartApi, CartRequest, CartRequestActionEnum} from '../../client/openapitools/marketplace';

const initialState = {
    sellerCartGroup: [],
    addToCartLoading: false,
    updateCartLoading: false,
    deleteCartLoading: false,
    mergeCartLoading: false,
    noOfItems: 0,
    noOfPackages: 0,
    currency: 'USD',
    price: '0',
    shippingCost: '0',
    totalPrice: '0',
    messages: [],
    cartId: null,
};

export const addToCart = createAsyncThunk('cart/addToCart', async (cartItem: CartRequest, apiConfig: Configuration) => {
    const cartApi = new CartApi(apiConfig);
    cartItem.action = CartRequestActionEnum.Add;
    const result = await cartApi.update(cartItem);

    return result.data;
});

export const updateCart = createAsyncThunk('cart/updateCart', async (cartItem: CartRequest, apiConfig: Configuration) => {
    const cartApi = new CartApi(apiConfig);
    cartItem.action = CartRequestActionEnum.Update;

    const result = await cartApi.update(cartItem);
    if (result?.data?.messages?.length > 0) {
        // throw new Error('Api request failed');
    }

    return result.data;
});

export const deleteCart = createAsyncThunk('cart/deleteCart', async (cartItem: CartRequest, apiConfig: Configuration) => {
    const cartApi = new CartApi(apiConfig);
    cartItem.action = CartRequestActionEnum.Delete;
    const result = await cartApi.update(cartItem);
    if (result?.data?.messages?.length > 0) {
        // throw new Error('Api request failed');
    }

    return result.data;
});

export const deleteCartAll = createAsyncThunk('cart/delete', async (cartItem: CartRequest, apiConfig: Configuration) => {
    const cartApi = new CartApi(apiConfig);
    cartItem.action = CartRequestActionEnum.Delete;
    const result = await cartApi._delete(cartItem);
    if (result?.data?.messages?.length > 0) {
        // throw new Error('Api request failed');
    }

    return result.data;
});

export const mergeCart = createAsyncThunk('cart/mergeCart', async (cartItem: CartRequest, apiConfig: Configuration) => {
    const cartApi = new CartApi(apiConfig);
    cartItem.action = CartRequestActionEnum.Merge;
    const result = await cartApi.update(cartItem);
    if (result?.data?.messages?.length > 0) {
        // throw new Error('Api request failed');
    }

    return result.data;
});

export const getCart = createAsyncThunk('cart/getCart', async (cartSessionId: string, apiConfig: Configuration) => {
    const cartApi = new CartApi(apiConfig);
    if (cartSessionId) {
        return await (
            await cartApi.getCartBySessionId(cartSessionId)
        )?.data;
    } else {
        return await (
            await cartApi.getCartByUserId()
        )?.data;
    }
});

export const addSessionId = createAction<string>('cart/addSessionId');
export const clearSessionId = createAction('cart/clearSessionId');

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        mergeUserCart: (state, action) => {
            // localStorage.removeItem('deviceId');
            return {
                ...state,
                ...action.payload,
                mergeCartLoading: false,
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(addToCart.pending, state => {
            state.addToCartLoading = true;
        });
        builder.addCase(addToCart.rejected, state => {
            state.addToCartLoading = false;
        });
        builder.addCase(addToCart.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
                addToCartLoading: false,
            };
        });
        builder.addCase(getCart.pending, state => {
            state.addToCartLoading = true;
        });
        builder.addCase(getCart.rejected, () => {
            return {
                ...initialState,
                addToCartLoading: false,
            };
        });
        builder.addCase(getCart.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
                addToCartLoading: false,
            };
        });
        builder.addCase(updateCart.pending, state => {
            state.updateCartLoading = true;
        });
        builder.addCase(updateCart.rejected, state => {
            state.updateCartLoading = false;
        });
        builder.addCase(updateCart.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
                updateCartLoading: false,
            };
        });
        builder.addCase(mergeCart.pending, state => {
            state.mergeCartLoading = true;
        });
        builder.addCase(mergeCart.rejected, state => {
            state.mergeCartLoading = false;
        });
        builder.addCase(mergeCart.fulfilled, (state, action) => {
            // localStorage.removeItem('deviceId');
            return {
                ...state,
                ...action.payload,
                mergeCartLoading: false,
            };
        });
        builder.addCase(deleteCart.pending, state => {
            state.deleteCartLoading = true;
        });
        builder.addCase(deleteCart.rejected, state => {
            state.deleteCartLoading = false;
        });
        builder.addCase(deleteCart.fulfilled, (state, action) => {
            if (action?.payload?.noOfItems > 0) {
                return {
                    ...state,
                    ...action.payload,
                    deleteCartLoading: false,
                };
            } else {
                return {
                    ...state,
                    ...initialState,
                    deleteCartLoading: false,
                };
            }
        });
        builder.addCase(addSessionId, (state, action) => {
            state.cartId = action.payload;
        });
        builder.addCase(clearSessionId, state => {
            state.cartId = undefined;
        });
    },
});

export const {mergeUserCart} = cartSlice.actions;

export default cartSlice.reducer;
