/* eslint-disable @typescript-eslint/no-unused-vars */
import {Chip, Drawer, IconButton, MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ChevronLeft, Close} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import SetSliderClient from '../../../../client/s3/homepage/setSliderClient';
import CarouselClient from '../../../../client/s3/mpCarousel/carouselClient';
import SliderClient from '../../../../client/s3/mpCarousel/sliderClient';
import NavigationClient from '../../../../client/s3/mpNavigation/navigationClient';
import BSCImage from '../../../../features/common/bscImage/bscImage';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import SubNavContent from '../../../../features/navigation/header/subNavContent';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../redux/reducers';
import PhoneSportSlider from './bestSellers/PhoneSportSlider';
import SportSlider from './bestSellers/SportSlider';
import HomeCarousel from './homeCarousel/homeCarousel';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    pwaHomeContentiOS: {
        overflowY: 'scroll',
        marginTop: 8,
        height: 'var(--pwaAvailableSpace)',
        zIndex: -1,
    },
    pwaHomeContentAndroid: {
        overflowY: 'scroll',
        marginTop: 8,
        height: 'var(--pwaAvailableSpace)',
        zIndex: -1,
    },
    padding: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '15px',
        },
    },
    homeCategories: {
        // margin: 'auto',
        padding: '12px 0 12px 8px',
        // border: '1px solid #00f',
        // height: 'calc(100vh - 453px)',
        // overflowY: 'scroll',
    },
    horizontalRibbon: {
        marginBottom: 6,
        width: '100%',
        overflowX: 'scroll',
        overflowY: 'hidden',
        ['&::-webkit-scrollbar']: {
            display: 'none !important',
        },
    },
    sliderNoWrap: {
        flexWrap: 'nowrap !important' as 'nowrap',
    },
    menuItem: {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        boxShadow:
            '0px 1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px -1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px 3.82222px 3.82222px rgba(255, 255, 255, 0.24)',
        borderRadius: 4,
        textAlign: 'center',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        ...theme.typography.button,
        textTransform: 'inherit',
        fontSize: theme.typography.body2.fontSize,
    },
    mobileSubNavContainer: {
        background: theme.palette.common.black,
        height: '100%',
        maxHeight: '100%',
    },
    menuTitle: {
        position: 'relative',
        color: theme.palette.common.white,
        padding: theme.spacing(2, 0),
        // background: 'linear-gradient(180deg, #41474D -0.23%, #030303 27.26%)',
        textAlign: 'center',
    },
    chevronLeft: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    mobileSubNavWrap: {
        padding: theme.spacing(0, 1),
        // maxHeight: 'calc(90vh-70px)',
        overflowY: 'scroll',
        height: 'calc(100% - 70px)',
    },
    drawer: {
        width: '100%',
    },
    normal: {
        fontStyle: 'normal',
    },
    capitalCase: {
        textTransform: 'none!important' as 'none',
    },
    fillBlack: {
        fill: 'black',
    },
    drawerPaperPWA: {
        top: 60,
    },
    emojiIcon: {
        width: 18,
        height: 18,
    },
}));
export type GraphData = {
    displayName: string;
    jobTitle: string;
    mail: string;
    businessPhones: string[];
    officeLocation: string;
};

const MenuStepEnum = {
    SPORTS: 0,
    SUB_NAV: 1,
};

function MobileDrawer({menus, open, onClose, menusLoading, setCurrentMenuStep, currentMenuStep, pwaSportsFilter}) {
    const classes = useStyles();
    const [selectedMenu, setSelectedMenu] = useState(null);

    useEffect(() => {
        if (open) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = null;
        }
    }, [open]);

    const [potentialNavigationRoutes, setPotentialNavigationRoutes] = useState([]);
    useEffect(() => {
        if (menus && menus.categories && menus.categories.length > 0) {
            const tempMenus = [];
            for (let x = 0; x < menus.categories.length; x++) {
                tempMenus.push({
                    focus: menus.categories[x].title,
                    url: menus.categories[x].url,
                });
                for (let y = 0; y < menus.categories[x].menus.length; y++) {
                    tempMenus.push({
                        focus: menus.categories[x].title + ' - ' + menus.categories[x].menus[y].title,
                        url: menus.categories[x].menus[y].url,
                    });
                    for (let z = 0; z < menus.categories[x].menus[y].links.length; z++) {
                        tempMenus.push({
                            focus:
                                menus.categories[x].title +
                                ' - ' +
                                menus.categories[x].menus[y].title +
                                ' - ' +
                                menus.categories[x].menus[y].links[z].title,
                            url: menus.categories[x].menus[y].links[z].url,
                        });
                    }
                }
            }
            setPotentialNavigationRoutes(tempMenus);
            if (pwaSportsFilter !== null) {
                setSelectedMenu(menus.categories.find(menu => menu.title === pwaSportsFilter));
            }
        }
    }, [menus, pwaSportsFilter]);

    return (
        <>
            <Drawer
                open={open}
                onClose={onClose}
                anchor="left"
                className={classes.drawer}
                PaperProps={{className: classes.drawer}}
                classes={{paper: pwaSportsFilter !== null ? classes.drawerPaperPWA : null}}
            >
                {pwaSportsFilter === null && (
                    <div className={classes.mobileSubNavContainer}>
                        <div className={classes.menuTitle}>
                            {currentMenuStep > MenuStepEnum.SPORTS && (
                                <IconButton className={classes.chevronLeft} onClick={() => setCurrentMenuStep(s => s - 1)}>
                                    <ChevronLeft fontSize="large" htmlColor="white" />
                                </IconButton>
                            )}
                            <BSCTypography
                                variant={currentMenuStep === 0 ? 'h2' : 'h3'}
                                capitalize
                                label={currentMenuStep === 0 ? 'Sports' : selectedMenu?.title.toLowerCase()}
                            />
                            <IconButton className={classes.closeButton} onClick={onClose}>
                                <Close fontSize="large" htmlColor="white" />
                            </IconButton>
                        </div>
                        <div className={classes.mobileSubNavWrap}>
                            <div>
                                {currentMenuStep === MenuStepEnum.SPORTS && (
                                    <>
                                        <BSCLoading loading={menusLoading} />
                                        {menus?.categories?.map(menu => (
                                            <MenuItem
                                                key={menu.order}
                                                className={classes.menuItem}
                                                onClick={() => {
                                                    setCurrentMenuStep(MenuStepEnum.SUB_NAV);
                                                    setSelectedMenu(menu);
                                                }}
                                            >
                                                {menu.title}
                                            </MenuItem>
                                        ))}
                                    </>
                                )}

                                {currentMenuStep === MenuStepEnum.SUB_NAV && (
                                    <SubNavContent
                                        menus={selectedMenu?.menus}
                                        menuId={selectedMenu?.order}
                                        onGoatClick={onClose}
                                        url={selectedMenu?.url}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {pwaSportsFilter !== null && selectedMenu && selectedMenu.menus && (
                    <div className={classes.mobileSubNavContainer}>
                        <div className={classes.menuTitle}>
                            <BSCTypography variant={'h3'} capitalize label={pwaSportsFilter} />
                            <IconButton className={classes.closeButton} onClick={onClose}>
                                <Close fontSize="large" htmlColor="white" />
                            </IconButton>
                        </div>
                        <div className={classes.mobileSubNavWrap}>
                            <SubNavContent
                                menus={selectedMenu?.menus}
                                menuId={selectedMenu?.order}
                                onGoatClick={onClose}
                                url={selectedMenu?.url}
                            />
                        </div>
                    </div>
                )}
            </Drawer>

            <div style={{position: 'fixed'}}>
                <div style={{position: 'absolute', top: -1500, left: -2500}}>
                    {potentialNavigationRoutes &&
                        potentialNavigationRoutes.map((navRoute, index) => (
                            <a href={navRoute.url} key={index} className={classes.navLinks}>
                                {navRoute.focus}
                            </a>
                        ))}
                </div>
            </div>
        </>
    );
}

export default function Home() {
    const classes = useStyles();
    const {isBelowTablet} = useScreenSnap();
    const location = useLocation();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {data: carouselItems} = useQuery(() => CarouselClient.getCarouselItems(), []);
    const {data: sliderItems} = useQuery(() => SliderClient.getSliderItems(), []);
    const {loading: menusLoading, data: menus} = useQuery(() => NavigationClient.getMenus(), []);
    const {loading: userMenuLoading, data: userMenu} = useQuery(() => NavigationClient.getUserMenus(), []);
    const {loading: setSliderLoading, data: setSliders} = useQuery(() => SetSliderClient.getSets(), []);
    const [currentMenuStep, setCurrentMenuStep] = useState(0);

    const buildSportsIcon = (title: string) => {
        switch (title) {
            case 'Baseball':
                return <BSCImage src="/img/baseball.png" title="Baseball" alt="Baseball" className={classes.emojiIcon} />;
            case 'Basketball':
                return <BSCImage src="/img/basketball.png" title="Basketball" alt="Basketball" className={classes.emojiIcon} />;
            case 'Football':
                return <BSCImage src="/img/football.png" title="Football" alt="Football" className={classes.emojiIcon} />;
            case 'Hockey':
                return <BSCImage src="/img/hockey.png" title="Hockey" alt="Hockey" className={classes.emojiIcon} />;
            case 'Soccer':
                return <BSCImage src="/img/soccer.png" title="Soccer" alt="Soccer" className={classes.emojiIcon} />;
            case 'Other':
                return <></>;
            default:
                return <BSCImage src="/img/golf.png" title="Golf" alt="Golf" className={classes.emojiIcon} />;
        }
    };

    const sliderCategoriesOrder = [
        'players',
        'releases',
        'brands',
        'rainbow',
        'classic_sets',
        'sports',
        'lineup_legends',
        'teams',
        '1990s',
        'quality_qbs',
        'attributes',
        '2000s',
        'graders',
        '1980s',
    ];
    const [sliderData, setSliderData] = useState([]);
    useEffect(() => {
        let orderedSliderCategories = [];
        if (sliderItems && sliderItems.sliders) {
            for (let x = 0; x < sliderItems.sliders.length; x++) {
                const itemsArray = sliderItems.sliders[x].items;
                const filteredItemsArray = itemsArray.filter(itemsArrayElement => itemsArrayElement.title !== '');
                // and now - shuffle
                let currentIndex = filteredItemsArray.length;
                while (currentIndex !== 0) {
                    const randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;
                    [filteredItemsArray[currentIndex], filteredItemsArray[randomIndex]] = [
                        filteredItemsArray[randomIndex],
                        filteredItemsArray[currentIndex],
                    ];
                }
                sliderItems.sliders[x].items = filteredItemsArray;
            }
            for (let y = 0; y < sliderCategoriesOrder.length; y++) {
                const sliderSection: never[] = sliderItems.sliders.filter(sliderItem => sliderItem.slider_key === sliderCategoriesOrder[y]);
                if (sliderSection.length > 0) {
                    orderedSliderCategories = [...orderedSliderCategories, ...sliderSection];
                }
            }
            setSliderData(orderedSliderCategories);
        }
    }, [sliderItems]);

    const [pwaOS, setPwaOS] = useState(null);
    const [pwaInnerHeight, setPwaInnerHeight] = useState(0);
    const [pwaInnerWidth, setPwaInnerWidth] = useState(0);
    const [pwaSportsFilter, setPWASportsFilter] = useState(null);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
                setPwaInnerHeight(marketplaceState.pwaSettings.pwaInnerHeight);
                setPwaInnerWidth(marketplaceState.pwaSettings.pwaInnerWidth);
                document.documentElement.style.setProperty('--pwaAvailableSpace', `calc(100vh - ${marketplaceState.pwaReservedSpace}px)`);
            }
        }
    }, [marketplaceState]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    useEffect(() => {
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Grid
                container
                className={classNames({
                    [classes.pwaHomeContentiOS]: pwaOS === 'iOS',
                    [classes.pwaHomeContentAndroid]: pwaOS === 'Android',
                    null: pwaOS !== 'iOS' && pwaOS !== 'Android',
                })}
            >
                {(pwaOS === 'iOS' || pwaOS === 'Android') && (
                    <Grid
                        item
                        xs={12}
                        className={classes.horizontalRibbon}
                        style={{
                            position: 'relative',
                            top: 2,
                            margin: 'auto',
                            padding: '0 0 2px 8px',
                            background: '#e5e5e5',
                        }}
                    >
                        <Grid container direction="row" alignItems="center" justifyContent={'flex-start'} className={classes.sliderNoWrap}>
                            {menus?.categories?.map((menu, index) => (
                                <Chip
                                    key={index}
                                    icon={buildSportsIcon(menu.title)}
                                    label={menu.title}
                                    style={{
                                        background: '#fff',
                                        border: '2px solid #000',
                                        borderRadius: 8,
                                        margin: '0 4px 0 0',
                                        fontWeight: 700,
                                    }}
                                    onClick={() => {
                                        setPWASportsFilter(menu.title);
                                        setOpen(true);
                                    }}
                                />
                            ))}
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <HomeCarousel carouselItems={carouselItems} />
                </Grid>
                <Grid item xs={12}>
                    {sliderData && (
                        <>
                            {isBelowTablet && (
                                <div className={classes.homeCategories}>
                                    <PhoneSportSlider sliders={sliderData} />
                                </div>
                            )}
                            {!isBelowTablet && (
                                <div style={{margin: 'auto', padding: '20px 0 20px 20px'}}>
                                    <SportSlider sliders={sliderData} />
                                </div>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            <MobileDrawer
                open={open}
                menus={menus}
                onClose={handleClose}
                currentMenuStep={currentMenuStep}
                setCurrentMenuStep={setCurrentMenuStep}
                menusLoading={menusLoading}
                pwaSportsFilter={pwaSportsFilter}
            />
        </>
    );
}
