/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import * as constants from '../../../../../shared/constants';
import {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../../redux/reducers';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {numberWithCommas} from '../../../../../packages/core/src/util/util';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import {SearchParsed} from '../../../../../enums/filterEnum';
import {Item} from '../../../../../client/openapitools/search';

interface SearchListItemProps {
    data: Item;
    parsedQueryResults?: SearchParsed;
    onRadioChange: Function;
    onCheckboxChange: Function;
    searchTermKey: string;
    isBulkUpload?: boolean;
    hasGeneratedResults?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        background: '#DAE0E6',
        borderRadius: 4,
        marginBottom: theme.spacing(0.25),
        justifyContent: 'space-between',
        paddingRight: theme.spacing(1),
        boxShadow: 'inset 0px -2px 4px #DAE0E6',
    },
    label: {
        fontSize: theme.typography.subtitle2.fontSize,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        wordBreak: 'break-word',
        width: '130px',
        // [theme.breakpoints.down('sm')]: {
        //     width: '215px',
        // },
    },
    bulkUploadLabel: {
        fontSize: theme.typography.subtitle2.fontSize,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            width: '215px',
        },
    },
    labelResults: {
        fontSize: 10,
        color: theme.palette.secondary.light,
        fontWeight: 500,
    },
    checkBox: {
        padding: theme.spacing(0.25),
    },
}));

function SearchListItem({
    data,
    onRadioChange,
    onCheckboxChange,
    searchTermKey,
    isBulkUpload = false,
    hasGeneratedResults = false,
}: SearchListItemProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const sellerState = useSelector((state: RootState) => state.seller);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [isSellerStorefrontPresent, setIsSellerStorefrontPresent] = useState(false);

    const handleCheckboxChange = (searchTermKey, slug: string, label: string) => {
        dispatch({
            type: constants.SET_BULK_UPLOAD_SELECTIONS,
            payload: {category: searchTermKey, selection: slug},
        });
        switch (searchTermKey) {
            case 'setName':
                dispatch({
                    type: constants.BULK_UPLOAD_SET_NAME,
                    payload: {bulkUploadSetName: label},
                });
                break;
            case 'year':
                dispatch({
                    type: constants.BULK_UPLOAD_YEAR,
                    payload: {bulkUploadYear: label},
                });
                break;
            case 'variant':
                dispatch({
                    type: constants.BULK_UPLOAD_VARIANT,
                    payload: {bulkUploadVariant: label},
                });
                break;
            case 'variantName':
                dispatch({
                    type: constants.BULK_UPLOAD_VARIANT_NAME,
                    payload: {bulkUploadVariantName: label},
                });
                break;

            default:
                break;
        }
    };

    const [ndx, setNdx] = useState(-1);
    useEffect(() => {
        if (sellerState && sellerState.bulkUploadSelections) {
            setNdx(sellerState.bulkUploadSelections.findIndex(tempItem => tempItem.selection === data.slug));
        }
    }, [sellerState]);

    useEffect(() => {
        if (marketplaceState) {
            const tempSellerName = marketplaceState.sellerStorefront !== {} ? marketplaceState.sellerStorefront['sellerName'] : null;
            if (tempSellerName && tempSellerName !== null) {
                setIsSellerStorefrontPresent(true);
            } else {
                setIsSellerStorefrontPresent(false);
            }
        }
    }, [marketplaceState]);

    return (
        <Box className={classes.innerBox}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {!isBulkUpload && (
                    <>
                        {searchTermKey === 'red' ? (
                            <Radio
                                onChange={() => onRadioChange(searchTermKey, data.slug)}
                                color="primary"
                                checked={data.active}
                                value={data.slug}
                                className={classes.checkBox}
                            />
                        ) : (
                            <Checkbox
                                color="primary"
                                onChange={() => onCheckboxChange(searchTermKey, data.slug)}
                                checked={data.active}
                                value={data.slug}
                                className={classes.checkBox}
                            />
                        )}
                    </>
                )}
                {isBulkUpload && (
                    <Checkbox
                        color="primary"
                        onChange={() => handleCheckboxChange(searchTermKey, data.slug, data.label)}
                        checked={ndx > -1}
                        value={data.slug}
                        className={classes.checkBox}
                        disabled={hasGeneratedResults}
                    />
                )}
                {/* Check for size (20) and add ... */}
                <BSCTypography className={isBulkUpload ? classes.bulkUploadLabel : classes.label} label={data.label} title={data.label} />
            </div>
            {!isSellerStorefrontPresent && !isBulkUpload && (
                <BSCTypography className={classes.labelResults} label={`(${numberWithCommas(data.count) || '-'})`} />
            )}
        </Box>
    );
}

export default memo(SearchListItem);
