import {ReactNode, useState, useRef, MouseEvent as ReactMouseEvent, Children, cloneElement} from 'react';
import Grid, {GridProps} from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCScroller from '../bscScroller/bscScroller';
import classNames from 'classnames';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    buttonGroup: {
        height: 32,
    },
    buttonGroupSmallDevice: {
        height: 32,
        width: 40,
    },
    button: {
        width: 70,
        background: theme.palette.common.white,
        border: '1px solid #DAE0E6 !important',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        // borderRadius: 4,
        color: '#1F2327',
        // padding: '0 4px',
        ['& svg']: {
            position: 'absolute',
            right: 3,
            [theme.breakpoints.down('xs')]: {
                right: 0,
                fontSize: 16,
            },
        },
        ['&:hover']: {
            background: theme.palette.common.white,
            border: '1px solid #DAE0E6 !important',
            boxShadow: 'inset 0px -4px 4px #DAE0E6',
            // borderRadius: 4,
            color: '#1F2327',
        },
        padding: theme.spacing(0),
        paddingRight: theme.spacing(0.5),
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
            width: 65,
            padding: theme.spacing(0, 0.5),
            justifyContent: 'center',
            fontSize: 12,
        },
    },
    displayNone: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    buttonRight: {
        color: 'rgba(0, 0, 0, 0.54) !important',
        background: '#B8C2CC !important',
        width: 70,
        fontSize: 14,
        textTransform: 'none',
        padding: theme.spacing(0.75, 0.25),
    },
    popover: {
        zIndex: 9,
    },
    menuList: {},
    menuItem: {
        textAlign: 'center',
        justifyContent: 'center',
    },
}));

interface BSCSelectSplitButtonProps {
    leftButtonLabel: string | number | ReactNode;
    rightButtonLabel: string | number | ReactNode;
    children: ReactNode;
    alignItems?: GridProps['alignItems'];
    isSmallDevice: boolean;
}

function BSCSelectSplitButton({
    leftButtonLabel,
    rightButtonLabel,
    children,
    alignItems = 'center',
    isSmallDevice = false,
}: BSCSelectSplitButtonProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {isMobile} = useScreenSnap();
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: ReactMouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems={alignItems}>
            <Grid item xs={12}>
                <ButtonGroup
                    className={isSmallDevice ? classes.buttonGroupSmallDevice : classes.buttonGroup}
                    variant="contained"
                    color="primary"
                    aria-label="split button"
                >
                    <Button
                        ref={anchorRef}
                        className={classes.button}
                        style={isMobile ? {width: 60} : {}}
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select quantity"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        {leftButtonLabel}
                        <KeyboardArrowDown />
                    </Button>
                    <Button className={classNames(classes.buttonRight, classes.displayNone)} disabled>
                        {rightButtonLabel}
                    </Button>
                </ButtonGroup>
                <Popper
                    className={classes.popover}
                    placement="bottom"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal={false}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <BSCScroller maxHeight={250}>
                                <ClickAwayListener onClickAway={() => setOpen(false)}>
                                    <MenuList className={classes.menuList} id="split-button-menu">
                                        {/* TODO: Need to figure out how to correctly type this */}
                                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                        {Children.map(children, (child: undefined) => {
                                            return cloneElement(child, {
                                                onClick: e => {
                                                    child?.props?.onClick(e);
                                                    setOpen(false);
                                                },
                                                className: classes.menuItem,
                                            });
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </BSCScroller>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Grid>
        </Grid>
    );
}

export default BSCSelectSplitButton;
