import {Table, TableRow, TableCell, Grid, TableHead, TableBody, makeStyles, Theme} from '@material-ui/core';
import classNames from 'classnames';
import React, {useMemo, useState} from 'react';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';
import BSCCardBox from '../bscCardBox/bscCardBox';
import BSCHeaderBlock from '../bscHeaderBlock/bscHeaderBlock';
import BSCPagination from '../bscPagination/bscPagination';
//import BscResultsPerPage from '../bscResultsPerPage/bscResultsPerPage';
import BSCTypography from '../bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 8,
        boxShadow: 'none',
        padding: '0',
        marginBottom: theme.spacing(2),
        marginTop: '10px',

        // [theme.breakpoints.down('xs')]: {
        //     overflowX: 'scroll',
        // },
    },
    table: {
        minWidth: 505,
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
        '&:nth-child(even)': {
            backgroundColor: '#eee',
        },
        //     '&:first-child': {
        //         background: '#DAE0E5',
        //     },
    },
    th: {
        color: '#41474C',
        textAlign: 'right',
        padding: theme.spacing(1),
        //background: '#e5e5e5',
        border: '1px solid lightgrey',
        '&:first-child': {
            // borderRadius: '8px 0 0 0',
            textAlign: 'left',
        },
        '&:last-child': {
            textAlign: 'center',
        },
    },
    td: {
        borderBottom: '2px solid #e5e5e5',
        textAlign: 'right',
        '&:first-child': {
            textAlign: 'left',
        },
        '&:last-child': {
            textAlign: 'center',
        },
        padding: '6px 8px',
    },
    summary: {
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    daily: {
        fontSize: 14,
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    summaryBg: {
        '& th': {
            backgroundColor: '#DAE0E5 !important' as '#DAE0E5',
        },
        position: 'sticky',

        zIndex: 3,
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
    },
    paddingLeft: {
        paddingLeft: '10px',
    },
    scrollX: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'scroll',
        },
        width: '100%',
    },
    overridePara: {
        fontSize: 12,
        '& p': {
            fontSize: 12,
        },
    },
}));

/**
 * Represents a Props for the table component.
 */
export interface BscTableBlockProps<T> {
    /**
     * The Text to display.
     */
    blockHeader: string;
    /**
     * Any additional props to be passed to the Typography of the block header text
     */
    blockHeaderProps?;
    /**
     * the column configuration for the table
     */
    columns: BscTableBlockColumnDefinition<T>[];
    /**
     * The data array to display.
     * For Client side paging this should be the entire result set
     * For server side paging this should be just the data set to show.
     */
    data: T[];
    /**
     * static row displayed at the bottom of every page.
     */
    summaryRow?: T;
    /**
     *
     */
    loading?: boolean;
    /**
     * enables or disables all paging
     */
    enablePaging?: boolean;
    /**
     * enables or disables client side paging
     */
    clientSidePaging?: boolean;
    /**
     * Sets the "Viewing 1 of X {viewingItemType}"
     */
    viewingItemType?: string;
    /**
     * Sets the total number of items when client side paging is set to false
     */
    totalItems?: number;
    /**
     * Sets the size per pabe when client side paging is set to false
     */
    sizePerPage?: number;

    /**
     *  Called when the size per page number changess when client side paging is set to false
     */
    onSizePerPageChange?: (size: number) => void;
    /**
     *  Sets the page number when client side paging is set to false
     */
    page?: number;
    footer?: React.ReactNode;
    /**
     * Called when the page changes when client side paging is set to false
     */
    onPageChange?: (page: number) => void;
    hasPagination?: boolean;
}

export function BscTableBlock<T>({
    blockHeader,
    blockHeaderProps = {},
    data,
    enablePaging = false,
    clientSidePaging = true,
    totalItems,
    //sizePerPage,
    // viewingItemType,
    // onSizePerPageChange = () => {},
    onPageChange = () => {},
    hasPagination = true,
    page,
    footer,
    ...otherProps
}: BscTableBlockProps<T>) {
    const [internalPageSize] = useState<number>(10); //setPerPage
    const [internalPage, setInternalPage] = useState(1);

    // const handleSizePerPageChange = (size: number) => {
    //     if (clientSidePaging) {
    //         setPerPage(size);
    //     } else {
    //         onSizePerPageChange(size);
    //     }
    // };

    const pageSize = useMemo(
        () => internalPageSize, //(enablePaging && clientSidePaging ? internalPageSize : sizePerPage),
        [internalPageSize] //enablePaging, sizePerPage, clientSidePaging]
    );
    const currentPage = useMemo(
        () => (enablePaging && clientSidePaging ? internalPage : page),
        [enablePaging, internalPage, page, clientSidePaging]
    );

    const startItemNum = useMemo(() => pageSize * (currentPage - 1) + 1, [pageSize, currentPage]);
    const endItemNum = useMemo(() => pageSize * currentPage, [pageSize, currentPage]);

    const total = useMemo(
        () => (enablePaging && clientSidePaging ? data?.length ?? 0 : totalItems),
        [enablePaging, clientSidePaging, data, totalItems]
    );

    const dataItems = useMemo(
        () => (enablePaging && clientSidePaging ? data.slice(startItemNum - 1, endItemNum) : data),
        [enablePaging, clientSidePaging, data, startItemNum, endItemNum]
    );

    const classes = useStyles();
    const totalPages = Math.ceil(total / internalPageSize);
    const handlePageChange = page => {
        if (page > 0 && page <= totalPages) {
            if (clientSidePaging) {
                setInternalPage(page);
            } else {
                onPageChange(page);
            }
        }
    };

    //  const {isBelowTablet} = useScreenSnap();

    return (
        <>
            <BSCCardBox className={classes.root}>
                <BSCHeaderBlock label={blockHeader} {...blockHeaderProps} />
                {/*  <BSCTypography align="center" label={blockHeader} variant="body2" {...blockHeaderProps} />
                <Grid container direction="row" justifyContent={isBelowTablet ? 'center' : 'space-between'} spacing={2}>
                    <Grid item style={{order: isBelowTablet ? 2 : 1}} xs={isBelowTablet ? 6 : false}>
                        {enablePaging && (
                            <BSCTypography
                                className={classes.paddingLeft}
                                label={`Viewing ${
                                    (total ?? 0) > 0 ? startItemNum + '-' + (endItemNum > total ? total : endItemNum) : '0'
                                } of ${(total ?? 0).toString()} ${viewingItemType}`}
                            />
                        )}
                    </Grid>

                    <Grid item style={{order: isBelowTablet ? 1 : 2}}>
                        <BSCTypography align="center" label={blockHeader} variant="body2" {...blockHeaderProps} />
                    </Grid>
                    <Grid item style={{order: 3}} xs={isBelowTablet ? 6 : false}>
                        {enablePaging && (
                            <BscResultsPerPage showArrow={!isBelowTablet} value={pageSize} onChange={handleSizePerPageChange} />
                        )}
                    </Grid>
                </Grid> */}
                <BscTableBlockTable
                    enablePaging={enablePaging}
                    onPageChange={handlePageChange}
                    totalItems={total}
                    sizePerPage={pageSize}
                    page={currentPage}
                    data={dataItems}
                    hasPagination={hasPagination}
                    {...otherProps}
                ></BscTableBlockTable>
            </BSCCardBox>
            {footer && footer}
        </>
    );
}

export interface BscTableBlockColumnDefinition<T> {
    /**
     * The column header text
     */
    header: string | React.ReactNode;
    /**
     * a callback to select the data.  can be as simple as x => x.prop
     */
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSelector: (data: T) => undefined;
    /**
     * the render function for the row.  Can be as simple as x => x or can be any react node.
     */
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderFunc?: (value: undefined) => React.ReactNode;
}

export interface BscTableBlockTableRowProps<T> {
    /**
     * The object to render in the row
     */
    data: T;
    /**
     * The column definitions with data selectors and render functions
     */
    columns: BscTableBlockColumnDefinition<T>[];
    className?;
}

export function BscTableBlockTableRow<T>({data, columns, className = null}: BscTableBlockTableRowProps<T>) {
    const classes = useStyles();
    const c = className ?? classes.tr;
    const {isBelowTablet} = useScreenSnap();
    return (
        <TableRow className={c}>
            {columns.map((e, index) => (
                <TableCell className={classes.td} key={index}>
                    {e?.renderFunc ? (
                        <div className={classes.overridePara}>{e.renderFunc(e.dataSelector(data))}</div>
                    ) : (
                        <BSCTypography size12={isBelowTablet} size14={!isBelowTablet}>
                            {e.dataSelector(data)}
                        </BSCTypography>
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
}

export interface BscTableBlockTableProps<T> {
    /**
     * the column defnitions of the table
     */
    columns: BscTableBlockColumnDefinition<T>[];
    /**
     * the data rows to show.
     */
    data: T[];
    /**
     * a summaryRow to show at the bottom of the data table
     */
    summaryRow?: T;
    /**
     *
     */
    noDataMessage?: string;
    /**
     *
     */
    loading?: boolean;
    /**
     * Display the pagination control
     */
    enablePaging?: boolean;
    /**
     * used by the pagination component to determine how many pages to show
     */
    totalItems?: number;
    /**
     * used by the pagination component to determine how many pages to show
     */
    sizePerPage?: number;
    /**
     * sets the current page number
     */
    page?: number;
    /**
     * Called when the page is changed by the pagination component
     */
    onPageChange?: (page) => void;
    hasPagination: boolean;
}

export function BscTableBlockTable<T>({
    columns,
    data,
    summaryRow,
    noDataMessage = 'No data returned',
    enablePaging = false,
    totalItems,
    sizePerPage,
    page,
    onPageChange,
    hasPagination = true,
}: BscTableBlockTableProps<T>) {
    const classes = useStyles();
    const {isBelowTablet} = useScreenSnap();
    return (
        <Grid container justifyContent="center">
            <Grid item className={classes.scrollX}>
                <Table className={classes.table} stickyHeader>
                    <TableHead className={classes.stickyHeader}>
                        <TableRow>
                            {columns?.map((e, index) => (
                                <TableCell key={index} className={classes.th}>
                                    <BSCTypography label={e.header} size12={isBelowTablet} size14={!isBelowTablet} semibold />
                                </TableCell>
                            ))}
                        </TableRow>
                        {summaryRow && (
                            <BscTableBlockTableRow
                                className={classNames(classes.th, classes.summaryBg)}
                                data={summaryRow}
                                columns={columns}
                            ></BscTableBlockTableRow>
                        )}
                    </TableHead>
                    <TableBody>
                        {(!data || data.length <= 0) && (
                            <TableRow>
                                <TableCell colSpan={columns?.length}>
                                    <BSCTypography center label={noDataMessage} />
                                </TableCell>
                            </TableRow>
                        )}
                        {data &&
                            data.map((x, index) => <BscTableBlockTableRow key={index} data={x} columns={columns}></BscTableBlockTableRow>)}
                    </TableBody>
                </Table>
            </Grid>
            {enablePaging && (
                <Grid item>
                    {hasPagination && (
                        <BSCPagination
                            total={totalItems}
                            sizePerPage={sizePerPage}
                            defaultPage={page}
                            onChange={(event, page) => onPageChange(page)}
                        />
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export default BscTableBlock;
