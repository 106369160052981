import {combineReducers} from 'redux';
import userReducer from './slices/users.slice';
import cartReducer from './slices/cart.slice';
import toastReducer from './slices/toast.slice';
import portalReducer from './slices/portal.slice';
import marketplaceReducer from './reducers/marketplace';
import sellerReducer from './reducers/seller';
import homePageReducer from './slices/homePage.slice';
import apiConfigReducer from './slices/apiConfig.slice';
import sellersLockerReducer from './slices/sellerLocker.slice';
import marketplaceSearchReducer from './slices/marketplaceSearch.slice';
const appReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    toast: toastReducer,
    portal: portalReducer,
    marketplace: marketplaceReducer,
    seller: sellerReducer,
    navigation: homePageReducer,
    apiConfig: apiConfigReducer,
    sellersLocker: sellersLockerReducer,
    marketplaceSearch: marketplaceSearchReducer,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'USER_LOGGED_OUT') {
        state = undefined;
        localStorage.removeItem('persist:root');
        localStorage.removeItem('sellerStorefrontId');
        localStorage.removeItem('sellerStorefrontName');
        localStorage.removeItem('sessionInStock');
        localStorage.removeItem('currentSearchQuery');
    }

    return appReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
