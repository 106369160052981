/* eslint-disable */
import {Drawer, FormControlLabel, ModalProps, Slide} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {TransitionProps} from '@material-ui/core/transitions';
import React, {useEffect, useState} from 'react';
import BSCRadio from '../../../../features/common/bscRadio/bscRadio';
import BSCRadioGroup from '../../../../features/common/bscRadioGroup/bscRadioGroup';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {RootState} from '../../../../redux/reducers';
import {useSelector} from 'react-redux';

interface PWASearchSortComponentProps extends Omit<ModalProps, 'children'> {}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    drawer: {
        width: '100%',
    },
    paper: {
        zIndex: 1,
        position: 'relative',
        margin: theme.spacing(1),
    },
    drawerPaperPWAiOS: {
        top: 'calc(100vh * .25)',
        borderRadius: '32px 32px 0 0',
        position: 'fixed',
        overflowX: 'hidden',
    },
    drawerPaperPWAAndroid: {
        top: 315,
        borderRadius: '32px 32px 0 0',
        position: 'fixed',
        overflowX: 'hidden',
    },
    dialogPaper: {
        height: 504,
        width: '100%',
        margin: 0,
        paddingBottom: 16,
        borderRadius: '32px 32px 0 0',
        position: 'fixed',
        bottom: 0,
        overflowX: 'hidden',
    },
    sortOptionsContent: {
        padding: '0 !important',
    },
    sortOption: {
        borderBottom: '2px solid rgb(230, 230, 230)',
        width: '100%',
        height: 48,
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 16,
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {children?: React.ReactElement<undefined>},
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PWASearchSortComponent({open, onSortChange, currentSortOption, onClose = () => {}}: PWASearchSortComponentProps) {
    const classes = useStyles();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [sortOptionValue, setSortOptionValue] = useState('best-sellers');
    const handleSortOptionChange = e => {
        setSortOptionValue(e.target.value);
        onSortChange(e.target.value);
        onClose({}, 'backdropClick');
    };

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        if (currentSortOption) {
            setSortOptionValue(currentSortOption);
        }
    }, [currentSortOption]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="bottom"
            className={classes.drawer}
            PaperProps={{className: classes.drawer}}
            classes={{paper: pwaOS === 'iOS' ? classes.drawerPaperPWAiOS : classes.drawerPaperPWAAndroid}}
            transitionDuration={350}
        >
            <BSCTypography
                label="Sort By:"
                size14
                style={{
                    fontSize: 24,
                    fontWeight: 600,
                    padding: '8px 0px',
                    textAlign: 'center',
                    borderBottom: '2px solid rgb(224, 224, 224)',
                }}
            />
            <BSCRadioGroup
                value={sortOptionValue}
                start
                noLabel={true}
                style={{alignItems: 'start', flexDirection: 'column', width: '100%', margin: 0}}
                hasFullWidth={true}
                onChange={e => {
                    handleSortOptionChange(e);
                }}
            >
                <FormControlLabel
                    className={classes.sortOption}
                    value="best-sellers"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Relevance</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="recently-added"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Recently Added</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="raw-price-hi-lo"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Price: Hi to Low</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="raw-price-lo-hi"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Price: Low to Hi</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="card-no-1-z"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Card #</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="newest"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Newest</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="oldest"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Oldest</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="a-z"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>A to Z</BSCTypography>}
                />
                <FormControlLabel
                    className={classes.sortOption}
                    value="z-a"
                    control={<BSCRadio />}
                    label={<BSCTypography size14>Z to A</BSCTypography>}
                />
            </BSCRadioGroup>
        </Drawer>
    );
}

export default PWASearchSortComponent;
