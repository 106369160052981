import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {SelectedSort} from '../../../../@types/selectedSort/selectSort';
import {RootState} from '../../../../redux/reducers';
import {cleanSearchObject} from '../../../../util/searchCleaner';
import {QueryString} from '../util/queryString';
import {URLBuilder} from '../util/urlBuilder';

function useSearch(baseUrl = 'search') {
    const {search} = useLocation();
    const history = useHistory();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    // TODO: this is truly any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedQuery: undefined = QueryString.parse(search);

    const [incomingSearch, setIncomingSearch] = useState(search);
    const [cleanedSearchQuery, setCleanedSearchQuery] = useState(cleanSearchObject(parsedQuery));
    useEffect(() => {
        if (search) {
            setIncomingSearch(search);
        }
        setCleanedSearchQuery(cleanSearchObject(parsedQuery));
    }, [search]);

    const [sellerId, setSellerId] = useState(null);
    const [sellerName, setSellerName] = useState(null);

    const handleRadioChange = useCallback(
        (searchTerm, isMyInventory, clearTerms = false) => {
            let obj = {
                ...cleanedSearchQuery,
                p: 0,
                q: searchTerm,
                myInventory: isMyInventory,
            };
            if (clearTerms === true) {
                obj = {p: 0, q: searchTerm, myInventory: isMyInventory};
            }
            if (sellerId && sellerName) {
                obj['sellerId'] = sellerId;
                obj['sellerName'] = sellerName;
            }
            const newString = QueryString.stringify(obj);
            // window.scrollTo(0, 0);
            history.push(`${baseUrl}?${newString}`);
        },
        [cleanedSearchQuery, history, baseUrl]
    );

    const handleCheckboxChange = useCallback(
        (searchTermKey: string, selectedSlug: string) => {
            const pq = QueryString.parse(history.location.search);
            const urlBuilder = URLBuilder(cleanedSearchQuery);
            const hasParamValue = urlBuilder.hasValueInParam(searchTermKey, selectedSlug);
            const obj = !hasParamValue
                ? urlBuilder.addToParam(searchTermKey, selectedSlug)
                : urlBuilder.removeParam(searchTermKey, selectedSlug);
            obj.q = pq.q;
            obj.p = 0;

            setCleanedSearchQuery(obj);

            if (sellerId && sellerName) {
                obj['sellerId'] = sellerId;
                obj['sellerName'] = sellerName;
            }
            const newStringifiedSearchString = QueryString.stringify(obj);
            // window.scrollTo(0, 0);
            history.push(`${baseUrl}?${newStringifiedSearchString}`);
        },
        [cleanedSearchQuery, history, baseUrl]
    );

    const handleParamDelete = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (searchTermKey: string, selectedSlug: string) => {
            const pq = QueryString.parse(history.location.search);
            pq.p = '0';
            const csq = cleanSearchObject(pq);

            let refactoredArray = [];
            if (csq[searchTermKey] instanceof Array) {
                refactoredArray = csq[searchTermKey].filter(item => item !== selectedSlug);
            }

            if (refactoredArray?.length === 0) {
                delete csq[searchTermKey];
            }

            setCleanedSearchQuery(csq);

            const obj = {
                ...csq,
            };
            if (sellerId && sellerName) {
                obj['sellerId'] = sellerId;
                obj['sellerName'] = sellerName;
            }
            const newStringifiedSearchString = QueryString.stringify(obj);
            // window.scrollTo(0, 0);
            history.push(`${baseUrl}?${newStringifiedSearchString}`);
        },
        [cleanedSearchQuery, history, baseUrl]
    );

    const handleClearAll = useCallback(() => {
        const pq = QueryString.parse(history.location.search);
        const csq = cleanSearchObject(pq);

        const obj = {
            p: 0,
            inStock: csq.inStock,
            myInventory: csq.myInventory,
            gradeRangeStart: csq?.gradeRangeStart,
            gradeRangeEnd: csq?.gradeRangeEnd,
            gradingCompany: csq?.gradingCompany,
            letterGrade: csq?.letterGrade,
            rawCondition: csq?.rawCondition,
            priceRangeStart: csq?.priceRangeStart,
            priceRangeEnd: csq?.priceRangeEnd,
        };
        if (sellerId && sellerName) {
            obj['sellerId'] = sellerId;
            obj['sellerName'] = sellerName;
        }
        const newString = QueryString.stringify(obj);
        // window.scrollTo(0, 0);
        history.push(`${baseUrl}?${newString}`);
    }, [location, history, baseUrl]);

    const handleSortChange = useCallback(
        (sortValue: SelectedSort['selectedSortValues']) => {
            let newStringifiedSearchString;
            const pq = QueryString.parse(history.location.search);
            const sellerURI = checkForSellerURI();
            if (sellerURI !== undefined && sellerURI !== null) {
                newStringifiedSearchString = QueryString.stringify({
                    ...cleanedSearchQuery,
                    sort: sortValue,
                    sellerId: sellerURI.sellerId,
                    sellerName: sellerName,
                    p: 0,
                    q: pq.q,
                });
            } else {
                newStringifiedSearchString = QueryString.stringify({
                    ...cleanedSearchQuery,
                    sort: sortValue,
                    p: 0,
                    q: pq.q,
                });
            }
            // window.scrollTo(0, 0);
            history.push(`${baseUrl}?${newStringifiedSearchString}`);
        },
        [history, cleanedSearchQuery, baseUrl]
    );

    const handlePaging = useCallback(
        (_event, nextPage: number) => {
            let newStringifiedSearchString;
            const sellerURI = checkForSellerURI();
            const pq = QueryString.parse(history.location.search);
            delete pq['p'];
            if (sellerURI !== undefined && sellerURI !== null) {
                newStringifiedSearchString = QueryString.stringify({
                    ...pq,
                    sellerId: sellerURI.sellerId,
                    sellerName: sellerName,
                    p: nextPage,
                });
            } else {
                newStringifiedSearchString = QueryString.stringify({
                    ...pq,
                    p: nextPage,
                });
            }
            history.push(`${baseUrl}?${newStringifiedSearchString}`);
        },
        [history, cleanedSearchQuery, baseUrl]
    );

    const handleStockToggleChange = useCallback(
        value => {
            const pq = QueryString.parse(history.location.search);
            const csq = cleanSearchObject(pq);

            const obj = {
                ...csq,
                p: 0,
                inStock: value,
            };
            if (sellerId && sellerName) {
                obj['sellerId'] = sellerId;
                obj['sellerName'] = sellerName;
            }
            const newString = QueryString.stringify(obj);
            // window.scrollTo(0, 0);
            history.push(`${baseUrl}?${newString}`);
        },
        [location, history, baseUrl]
    );

    const handleConditionsChange = useCallback(
        value => {
            const pq = QueryString.parse(history.location.search);
            const csq = cleanSearchObject(pq);

            // reset the board
            delete csq.type;
            delete csq.rawCondition;
            delete csq.gradingCompany;
            delete csq.gradeRangeStart;
            delete csq.gradeRangeEnd;
            delete csq.letterGrade;

            const obj = {
                ...csq,
                p: 0,
            };

            if (value?.raw?.length > 0) {
                obj.type = 'raw';
                obj.rawCondition = value.raw;
            }
            if (value?.graded?.company?.length > 0) {
                obj.type = 'graded';
                obj.gradingCompany = value.graded.company;
                obj.gradeRangeStart = value.graded.rangeStart;
                obj.gradeRangeEnd = value.graded.rangeEnd;
            }
            if (value?.graded?.letterGrades?.length > 0) {
                obj.letterGrade = value.graded.letterGrades;
            }
            if (value?.raw?.length > 0 && value?.graded?.company?.length > 0) {
                obj.type = 'all';
            }

            if (sellerId && sellerName) {
                obj['sellerId'] = sellerId;
                obj['sellerName'] = sellerName;
            }
            const newString = QueryString.fuse(obj);
            // window.scrollTo(0, 0);
            history.push(`${baseUrl}?${newString}`);
        },
        [location, history, baseUrl]
    );

    const handlePriceRangeChange = useCallback(
        value => {
            const pq = QueryString.parse(history.location.search);
            const csq = cleanSearchObject(pq);
            // reset the board
            delete csq.priceRangeStart;
            delete csq.priceRangeEnd;

            const obj = {
                ...csq,
                p: 0,
                priceRangeStart: value.priceRangeStart,
                priceRangeEnd: value?.priceRangeEnd,
            };
            if (sellerId && sellerName) {
                obj['sellerId'] = sellerId;
                obj['sellerName'] = sellerName;
            }
            const newString = QueryString.stringify(obj);
            // window.scrollTo(0, 0);
            history.push(`${baseUrl}?${newString}`);
        },
        [location, history, baseUrl]
    );

    const checkForSellerURI = () => {
        const pq = QueryString.parse(history.location.search);
        const csq = cleanSearchObject(pq);
        if (sellerId === null) {
            return null;
        }
        const obj = {
            ...csq,
            sellerId: sellerId,
            sellerName: sellerName,
        };
        return obj;
    };

    const [searchSortParam, setSearchSortParam] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.searchSortParam !== '') {
                setSearchSortParam(marketplaceState.searchSortParam);
            }
            const tempSellerId = marketplaceState.sellerStorefront['sellerId'];
            setSellerId(tempSellerId);
            const tempSellerName = marketplaceState.sellerStorefront['sellerName'];
            setSellerName(tempSellerName);
            if (tempSellerId !== null && tempSellerId !== undefined) {
                if (sellerId === null || sellerId === undefined) {
                    const pq = QueryString.parse(history.location.search);
                    const csq = cleanSearchObject(pq);
                    const obj = {
                        ...csq,
                        sellerId: tempSellerId,
                        sellerName: tempSellerName,
                    };
                    const newString = QueryString.stringify(obj);
                    // window.scrollTo(0, 0);
                    history.push(`${baseUrl}?${newString}`);
                }
            } else {
                const pq = QueryString.parse(history.location.search);
                const csq = cleanSearchObject(pq);
                delete csq.sellerId;
                delete csq.sellerName;
                const obj = {
                    ...csq,
                };
                const newString = QueryString.stringify(obj);
                history.push(`${baseUrl}?${newString}`);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        const is = QueryString.parse(incomingSearch);
        const pq = QueryString.parse(history.location.search);
        const csq = cleanSearchObject(pq);
        const obj = {
            ...csq,
        };
        if (sellerId && sellerName) {
            obj['sellerId'] = sellerId;
            obj['sellerName'] = sellerName;
        } else {
            if (is.sellerId !== undefined && is.sellerId !== null) {
                obj['sellerId'] = is.sellerId;
                obj['sellerName'] = is.sellerName;
            }
        }
        if (searchSortParam !== undefined && searchSortParam !== null) {
            obj['sort'] = searchSortParam;
        }
        const newString = QueryString.stringify(obj);
        // window.scrollTo(0, 0);
        history.push(`${baseUrl}?${newString}`);
    }, [cleanedSearchQuery]);

    return {
        search,
        cleanedSearchQuery,
        parsedQuery,
        handlePaging,
        handleSortChange,
        handleClearAll,
        handleParamDelete,
        handleCheckboxChange,
        handleRadioChange,
        handleStockToggleChange,
        handleConditionsChange,
        handlePriceRangeChange,
    };
}

export default useSearch;
