/* eslint-disable @typescript-eslint/no-unused-vars */
import {AuthenticationResult, EventType} from '@azure/msal-browser';
import {AccountInfo} from '@azure/msal-common';
import {useMsal} from '@azure/msal-react';
import {produce} from 'immer';
import React, {useEffect, useReducer} from 'react';
import {useDispatch} from 'react-redux';
import {AUTH_REQUESTS} from './authConfig';
import {Configuration} from './client/openapitools/marketplace/configuration';
import * as constants from './shared/constants';

interface AuthFunctions {
    logout(): void;
    login(redirectStartPage?: string): void;
    signUpPWA(redirectStartPage?: string): void;
    loginPWA(redirectStartPage?: string): void;
    resetPasswordPWA(redirectStartPage?: string): void;
    setAccessToken(accessToken: string): void;
}

const baseFunctions: AuthFunctions = {
    logout: () => {},
    login: () => {},
    signUpPWA: () => {},
    loginPWA: () => {},
    resetPasswordPWA: () => {},
    setAccessToken: () => {},
};

const apiAuthConfig = new Configuration({
    basePath: process.env.REACT_APP_API_BASE_URL,
    accessToken: undefined,
    baseOptions: undefined,
});

interface AuthState {
    isAuthenticated: boolean;
    isLoginInProgress: boolean;
    isLoginError: boolean;
    loginErrorMessage: string;
    accessToken: string;
    account: AccountInfo;
    apiAuthConfig: Configuration;
}

const baseAuthState: AuthState = {
    isAuthenticated: false,
    isLoginInProgress: false,
    isLoginError: false,
    loginErrorMessage: '',
    accessToken: '',
    account: {},
    apiAuthConfig,
};

const AuthStateContext = React.createContext(baseAuthState);
const AuthFunctionContext = React.createContext(baseFunctions);

const AuthStateProvider = AuthStateContext.Provider;
const AuthFunctionContextProvider = AuthFunctionContext.Provider;

export const useAuthState = (): AuthState => {
    const context = React.useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error('useAlertContext must be used within an AlertContextProvider');
    }
    return context;
};
export const useAuthFunctions = (): AuthFunctions => {
    const context = React.useContext(AuthFunctionContext);
    if (context === undefined) {
        throw new Error('useAlertContext must be used within an AlertContextProvider');
    }
    return context;
};

const reducerActions = {
    RESET: 'RESET',
    SET_LOGIN_INPROGRESS: 'SET_LOGIN_INPROGRESS',
    SET_LOGIN_ERROR: 'SET_LOGIN_ERROR',
    SET_LOGGED_IN: 'SET_LOGGED_IN',
    SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
    SET_ACCESS_TOKEN_AND_ACCOUNT: 'SET_ACCESS_TOKEN_AND_ACCOUNT',
};

const reducer = (draft, action) => {
    switch (action.type) {
        case reducerActions.SET_LOGIN_INPROGRESS:
            draft.isLoginInProgress = true;
            draft.accessToken = '';
            draft.account = {};
            draft.apiAuthConfig = {};
            break;
        case reducerActions.SET_LOGGED_IN:
            draft.isLoginInProgress = false;
            draft.isAuthenticated = true;
            draft.accessToken = action.payload.accessToken;
            draft.account = action.payload.account;
            draft.apiAuthConfig = {
                ...apiAuthConfig,
                accessToken: action.payload.accessToken,
            };
            break;
        case reducerActions.SET_LOGIN_ERROR:
            draft.isLoginInProgress = false;
            draft.isLoginError = true;
            draft.loginError = action.payload;
            draft.accessToken = '';
            draft.account = {};
            draft.apiAuthConfig = {};
            break;
        case reducerActions.SET_ACCESS_TOKEN:
            draft.isAuthenticated = true;
            draft.accessToken = action.payload.accessToken;
            draft.apiAuthConfig = {
                ...apiAuthConfig,
                accessToken: action.payload.accessToken,
            };
            break;
        case reducerActions.SET_ACCESS_TOKEN_AND_ACCOUNT:
            draft.isAuthenticated = true;
            draft.accessToken = action.payload.accessToken;
            draft.account = action.payload.account;
            draft.apiAuthConfig = {
                ...apiAuthConfig,
                accessToken: action.payload.accessToken,
            };
            break;
        case reducerActions.RESET:
            return baseAuthState;
    }
};

function Auth(props) {
    const {instance} = useMsal();
    const [authState, dispatch] = useReducer(produce(reducer), baseAuthState);
    const reduxDispatch = useDispatch();

    useEffect(() => {
        const eventCallbackId = instance.addEventCallback(async event => {
            if (event.eventType === EventType.LOGIN_START) {
                console.log('msal helper >>> LOGIN_START');
                dispatch({type: reducerActions.SET_LOGIN_INPROGRESS});
            }
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                console.log('msal helper >>> LOGIN_SUCCESS');
                const payload = event.payload as AuthenticationResult;
                const accessToken = payload.accessToken;
                const account = payload.account;
                instance.setActiveAccount(account);
                dispatch({
                    type: reducerActions.SET_ACCESS_TOKEN_AND_ACCOUNT,
                    payload: {accessToken, account},
                });
            }
            if (event.eventType === EventType.LOGIN_FAILURE) {
                console.log('msal helper >>> LOGIN_FAILURE');
                if (localStorage.getItem('isResetPassword') !== null) {
                    localStorage.removeItem('isResetPassword');
                } else {
                    instance.logoutRedirect().catch(e => {
                        console.error(e);
                    });
                }
            }

            if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                console.log('msal helper >>> ACQUIRE_TOKEN_SUCCESS');
                const payload = event.payload as AuthenticationResult;
                const accessToken = payload.accessToken;
                if (localStorage.getItem('accessToken') !== accessToken) {
                    localStorage.setItem('accessToken', accessToken);
                    dispatch({
                        type: reducerActions.SET_ACCESS_TOKEN,
                        payload: {accessToken},
                    });
                }
            }

            if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                console.log('msal helper >>> ACQUIRE_TOKEN_FAILURE');
            }
        });

        return () => {
            if (eventCallbackId) {
                instance.removeEventCallback(eventCallbackId);
            }
        };
    }, [instance]);

    const onSignIn = async (landingUrl: string) => {
        instance
            .loginRedirect({
                scopes: AUTH_REQUESTS.LOGIN.scopes,
                redirectStartPage: landingUrl,
            })
            .catch(e => {
                console.error(e);
            });
    };

    const onSignUpPWA = async (landingUrl: string) => {
        instance
            .loginRedirect({
                authority: process.env.REACT_APP_AUTHORITY_SIGNUP,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
                redirectStartPage: landingUrl,
            })
            .catch(e => {
                console.error(e);
            });
    };

    const onSignInPWA = async (landingUrl: string) => {
        instance
            .loginRedirect({
                authority: process.env.REACT_APP_AUTHORITY_SIGNIN,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
                redirectStartPage: landingUrl,
            })
            .catch(e => {
                console.error(e);
            });
    };

    const onResetPasswordPWA = async (landingUrl: string) => {
        instance
            .loginRedirect({
                authority: process.env.REACT_APP_AUTHORITY_PASSWORDRESET,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
                redirectStartPage: landingUrl,
            })
            .catch(e => {
                console.error(e);
            });
    };

    const onSignOut = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('_grecaptcha');
        localStorage.removeItem('cleanedSearchQuery');
        localStorage.removeItem('infiniteResultsTotal');
        localStorage.removeItem('scrollableId');
        localStorage.removeItem('currentSearchQuery');
        reduxDispatch({
            type: constants.ACCESS_TOKEN,
            payload: {accessToken: ''},
        });
        dispatch({type: reducerActions.RESET});
        reduxDispatch({type: 'USER_LOGGED_OUT'});
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    };

    const onSetAccessToken = (accessToken: string) => {
        if (localStorage.getItem('accessToken') !== accessToken) {
            localStorage.setItem('accessToken', accessToken);
            reduxDispatch({
                type: constants.ACCESS_TOKEN,
                payload: {accessToken},
            });
            dispatch({
                type: reducerActions.SET_ACCESS_TOKEN,
                payload: {accessToken},
            });
        }
    };

    const authProviderValue = {
        ...baseFunctions,
        login: onSignIn,
        signUpPWA: onSignUpPWA,
        loginPWA: onSignInPWA,
        resetPasswordPWA: onResetPasswordPWA,
        logout: onSignOut,
        setAccessToken: onSetAccessToken,
    };

    return (
        <AuthFunctionContextProvider value={authProviderValue}>
            <AuthStateProvider value={authState}>{props.children}</AuthStateProvider>
        </AuthFunctionContextProvider>
    );
}

export default Auth;
