/* eslint-disable @typescript-eslint/no-unused-vars */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {SwipeableDrawer} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {SellerApi as SellersLockerSearchApi} from '../../../../client/openapitools/search/api';
import {Configuration} from '../../../../client/openapitools/search/configuration';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCPagination from '../../../../features/common/bscPagination/bscPagination';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import useSearch from '../../../../packages/core/src/hooks/useSearch';
import {RootState} from '../../../../redux/reducers';
import {sellerSearchCreator} from '../../../../util/searchCreator';
import SearchSidebar from '../../../marketplace/pages/search/sidebar/SearchSidebar';
import MobileSLSearchResultsCard from './mobileSLSearchResultsCard';
import SLInventoryHeader from './slInventoryHeader';
import SLSearchResults from './slSearchResults';

const useStyles = makeStyles((theme: Theme) => ({
    drawerPaper: {
        width: '90%',
    },
    wrap: {
        marginBottom: theme.spacing(2),
        //padding: theme.spacing(1, 3),
        //overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: '99%',
        },
        flexDirection: 'row',
        // maxWidth: '99%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '99%',
        },
        // margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
    },
    wrapPWA: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: '99%',
        },
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '99%',
        },
        display: 'flex',
        justifyContent: 'space-between',
    },
    pwaWrapper: {
        height: 'calc(100vh - 220px)',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    sidebar: {
        padding: theme.spacing(1, 2),
        paddingRight: 0,
        position: 'sticky',
        top: 0,
        flex: 3,
        maxWidth: 300,
        alignSelf: 'flex-start',
        height: 'auto',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 300,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            display: 'none',
        },
        verticalAlign: 'top',
    },
    searchPageCol: {
        flexDirection: 'column',
        flex: 10,
        [theme.breakpoints.down('sm')]: {
            width: ' 99%',
        },
    },
}));

function SellersInventory() {
    const classes = useStyles();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let sellersLockerSearchApi = new SellersLockerSearchApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const sellerState = useSelector((state: RootState) => state.seller);
    const {userState} = useReduxUser();
    const {
        cleanedSearchQuery,
        handleCheckboxChange,
        handleClearAll,
        handlePaging,
        handleParamDelete,
        handleSortChange,
        handleRadioChange,
        handleConditionsChange,
        handlePriceRangeChange,
    } = useSearch('inventory');
    const {sort, p, q, condition, myInventory} = cleanedSearchQuery;
    const {isMobile, isTablet} = useScreenSnap();
    const belowTablet = isTablet || isMobile;
    const EMPTY_INVENTORY = 'Your Inventory is Empty';
    const [qSearchTerm, setqSearchTerm] = useState(null);

    const [isInitialMyCatalog, setIsInitialMyCatalog] = useState(true);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [sellersSearchResults, setSellersSearchResults] = useState(null);
    useEffect(() => {
        if (cleanedSearchQuery.q) {
            setqSearchTerm(cleanedSearchQuery['q']);
        } else {
            setqSearchTerm(null);
        }
        const {queryString, postObj} = sellerSearchCreator({terms: cleanedSearchQuery});
        if (queryString || postObj.myInventory === 'true') {
            postObj.sellerId = userState?.sellerProfile?.sellerId;
            setIsInitialMyCatalog(false);
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            setSellersSearchResults(null);
                            try {
                                setLoading(true);
                                const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                                sellersLockerSearchApi = new SellersLockerSearchApi(apiConfig);
                                const inventoryResults = await sellersLockerSearchApi.getResults1(postObj, queryString);
                                if (inventoryResults) {
                                    setSellersSearchResults(inventoryResults);
                                    setLoading(false);
                                } else {
                                    setErrors('No Results Found');
                                    setLoading(false);
                                }
                            } catch (error) {
                                console.log('error', error);
                                setErrors(error.description);
                                setLoading(false);
                            }
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    }, [cleanedSearchQuery]);

    const aggregations = sellersSearchResults?.data.aggregations;
    const searchResults = sellersSearchResults?.data.results;
    const totalResults = sellersSearchResults?.data.totalResults;
    // @ts-ignore This is actually right, just not on the swagger for some reason.
    let appliedFilters = sellersSearchResults?.data.appliedFilters;
    const totalAppliedFilters = appliedFilters?.length;
    const aggregatedValues = aggregations ?? null;
    const aggregatedKeys = aggregations && Object.keys(aggregations);

    const handleCloseDrawer = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const handleOpenDrawer = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleMobileFilter = useCallback(() => {
        handleOpenDrawer();
    }, []);

    const handlingRadioChange = (search, searchCatalogType, isSearchTermEntered) => {
        if (searchCatalogType === 'true') {
            setIsInitialMyCatalog(false);
        } else {
            if (search === undefined || search === null || search === '') {
                setIsInitialMyCatalog(true);
            }
        }
        handleRadioChange(search, searchCatalogType, isSearchTermEntered);
    };

    useEffect(() => {
        if (cleanedSearchQuery.q) appliedFilters = null;
    }, [cleanedSearchQuery.q]);

    const [isMyInventory, setIsMyInventory] = useState(false);
    useEffect(() => {
        if (cleanedSearchQuery) {
            const {myInventory} = cleanedSearchQuery;
            if (myInventory === 'true') {
                setIsMyInventory(true);
            } else {
                setIsMyInventory(false);
            }
        }
        if (appliedFilters && appliedFilters.length > 0 && cleanedSearchQuery.q) {
            for (let i = 0; i < appliedFilters.length; i++) {
                if (!cleanedSearchQuery[appliedFilters[i].type]) {
                    cleanedSearchQuery[appliedFilters[i].type] = [];
                }

                cleanedSearchQuery[appliedFilters[i].type].push(appliedFilters[i].slug);
            }
            cleanedSearchQuery.q = null;
            cleanedSearchQuery.condition = condition ?? 'all';
            cleanedSearchQuery.myInventory = myInventory ?? false;
            // refetch();
            //  const filter = QueryString.stringify(cleanedSearchQuery);
            //window.location.href = '/search?' + filter;
        }
    }, [cleanedSearchQuery, appliedFilters]);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    const [scrollableId, setScrollableId] = useState('');
    useEffect(() => {
        if (sellerState) {
            setScrollableId(sellerState.scrollableId);
        }
    }, [sellerState]);

    const handlePageChange = (evt, paginationPageNumber) => {
        handlePaging(null, paginationPageNumber - 1);
    };

    return (
        <div className={pwaOS === 'iOS' ? classes.wrapPWA : classes.wrap}>
            <Box className={classes.sidebar}>
                {belowTablet ? (
                    <SwipeableDrawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={handleCloseDrawer}
                        onOpen={handleOpenDrawer}
                        classes={{paper: classes.drawerPaper}}
                    >
                        <SearchSidebar
                            isMobile={belowTablet}
                            loading={loading}
                            errors={errors}
                            aggregatedKeys={aggregatedKeys}
                            chipList={appliedFilters}
                            aggregatedValues={aggregatedValues}
                            parsedQueryResults={cleanedSearchQuery}
                            onRadioChange={handleRadioChange}
                            onCheckboxChange={handleCheckboxChange}
                            onClearAll={handleClearAll}
                            onParamDelete={handleParamDelete}
                            onConditionsChange={handleConditionsChange}
                            onPriceRangeChange={handlePriceRangeChange}
                            onSortChange={handleSortChange}
                            selectedSortValue={sort}
                            showFindASeller={false}
                            conditionToggle={condition}
                            showInStock={false}
                            showCardType={myInventory === true}
                            showConditions={isMyInventory}
                            showPriceRange={isMyInventory}
                            showSortOrder={isMyInventory}
                        />
                    </SwipeableDrawer>
                ) : (
                    <SearchSidebar
                        isMobile={belowTablet}
                        loading={loading}
                        errors={errors}
                        aggregatedKeys={aggregatedKeys}
                        aggregatedValues={aggregatedValues}
                        chipList={appliedFilters}
                        parsedQueryResults={cleanedSearchQuery}
                        onRadioChange={handleRadioChange}
                        onCheckboxChange={handleCheckboxChange}
                        onClearAll={handleClearAll}
                        onParamDelete={handleParamDelete}
                        onConditionsChange={handleConditionsChange}
                        onPriceRangeChange={handlePriceRangeChange}
                        onSortChange={handleSortChange}
                        selectedSortValue={sort}
                        showFindASeller={false}
                        showInStock={false}
                        showCardType={myInventory === true}
                        showSelectSeller={false}
                        showConditions={isMyInventory}
                        showPriceRange={isMyInventory}
                        showSortOrder={isMyInventory}
                    />
                )}
            </Box>
            <div className={classes.searchPageCol}>
                <BSCContainer variant="fullWidth" className={pwaOS === 'iOS' ? classes.pwaWrapper : null}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SLInventoryHeader
                                belowTablet={belowTablet}
                                onMobileFilter={handleMobileFilter}
                                searchCatalogType={myInventory}
                                q={q}
                                totalAppliedFilters={totalAppliedFilters}
                                onRadioChange={handlingRadioChange}
                                handleClear={handleClearAll}
                            />
                            <BSCDivider />
                            {isMobile ? (
                                <MobileSLSearchResultsCard
                                    loading={loading}
                                    sellerSearchResults={searchResults}
                                    searchCatalogType={myInventory}
                                    isInitialMyCatalog={isInitialMyCatalog}
                                    noResultsMessage={q === undefined ? EMPTY_INVENTORY : `No Results Matching "${qSearchTerm}"`}
                                />
                            ) : (
                                <SLSearchResults
                                    loading={loading}
                                    onSortChange={handleSortChange}
                                    sellerSearchResults={searchResults}
                                    searchCatalogType={myInventory}
                                    scrollableId={scrollableId}
                                    isInitialMyCatalog={isInitialMyCatalog}
                                    noResultsMessage={q === undefined ? EMPTY_INVENTORY : `No Results Matching "${qSearchTerm}"`}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {totalResults > 0 && (
                        <>
                            <BSCDivider />
                            <BSCPagination
                                total={totalResults}
                                defaultPage={parseInt(p) + 1 > 0 ? parseInt(p) + 1 : 1}
                                sizePerPage={50}
                                onChange={handlePageChange}
                            />
                        </>
                    )}
                </BSCContainer>
            </div>
        </div>
    );
}

export default SellersInventory;
