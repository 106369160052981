import {ModalProps} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Alert} from '@material-ui/lab';
import BSCButton from '../../../../../../../features/common/bscButton/bscButton';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCModal from '../../../../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    marginBottom: {marginBottom: theme.spacing(2)},
}));

interface DeleteAccountModalProps extends Omit<ModalProps, 'children'> {
    onClose: (confirmation: boolean) => void;
}

const DeleteAccountModal = ({open, onClose}: DeleteAccountModalProps) => {
    const classes = useStyles();

    return (
        <BSCModal open={open} onClose={onClose}>
            <div>
                <BSCTypography align="center" variant="h3" label="Are You Sure?" />
                <BSCDivider />
                <div>
                    <Alert severity="warning" className={classes.marginBottom}>
                        Deleting your account will remove all data, history and listings associated with this account permanently. If you
                        proceed, this process may take 3-5 business days to fully complete.
                    </Alert>
                    <BSCButton color="primary" fullWidth onClick={() => onClose(true)}>
                        Confirm
                    </BSCButton>
                </div>
            </div>
        </BSCModal>
    );
};

export default DeleteAccountModal;
