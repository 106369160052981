/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import {Accordion, AccordionDetails, AccordionSummary, Grid, Link} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import CartUserNormalizer from '../../../../client/marketplace/cart/user/CartUserClientNormalizer';
import {SellerCartGroup} from '../../../../client/openapitools/marketplace';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import * as constants from '../../../../shared/constants';
import './cartDetail.scss';
import CheckoutCard from './checkoutCard';
import SellerSummaryCart from './sellerSummaryCart';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
    },
    nestedGrid: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
        },
    },
    container: {},
    summaryCard: {height: '100%'},
    marginBottom: {},
    summaryStyle: {
        color: '#000',
        background: '#B8C2CC',
        borderRadius: 0,
        marginBottom: 8,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 16,
        width: 'auto',
        overflow: 'hidden',
        fontSize: 14,
        boxSizing: 'border-box',
        minHeight: '48px !important',
        maxHeight: 48,
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: 1.5,
        whiteSpace: 'nowrap',
    },
    sellerLink: {
        textDecoration: 'underline',
        color: '#000',
        fontWeight: 'bold',
        padding: '2px 8px',
    },
}));

interface CartDetailProps {
    index: number;
    loading: boolean;
    total: number;
    groupItem: SellerCartGroup;
    lowDollarAmount: number;
    onDeleteCartItem?: (cartItemId) => void;
    onQuantitySelect?: (quantity, cartItem) => void;
    onExpansionPanel: (panelIndex) => void;
    expansionPanelIndex: number;
}

function CartDetail({
    index,
    total,
    groupItem,
    loading,
    onDeleteCartItem,
    onQuantitySelect,
    lowDollarAmount,
    onExpansionPanel,
    expansionPanelIndex,
}: CartDetailProps) {
    const classes = useStyles();
    const {isMobile} = useScreenSnap();
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = groupItem?.cartItems[0]?.productItem?.seller?.sellerProfile?.userId;
    const seller = groupItem?.cartItems[0]?.productItem?.seller?.sellerProfile?.sellerStoreName ?? 'Unknown';
    const sellerShippingTiers = groupItem?.cartItems[0]?.productItem?.seller?.sellerProfile?.shippingSettings;

    const handleChange = panelIndex => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (isExpanded) {
            onExpansionPanel(panelIndex);
        } else {
            onExpansionPanel(-1);
        }
    };

    const setLinkableSeller = (sellerId, sellerName) => {
        localStorage.removeItem('previousProductPageLink');
        localStorage.setItem('previousProductPageLink', window.location.pathname);
        setTimeout(() => {
            dispatch({
                type: constants.SELLER_STOREFRONT_SELECTED,
                payload: {sellerId: sellerId, sellerName: sellerName},
            });
            history.push('/search?inStock=true&p=0');
        }, 50);
    };

    return (
        <div style={isMobile ? {overflowX: 'hidden', overflowY: 'scroll'} : {}}>
            <Accordion square expanded={expansionPanelIndex === index} onChange={handleChange(index)}>
                <AccordionSummary id={'panel' + index} className={classes.summaryStyle} expandIcon={<ExpandMoreIcon />}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <div style={{display: 'inline-flex'}}>
                                <BSCTypography variant="body2" style={{paddingTop: 2, fontSize: 14}} label="Sold by:" />
                                <div
                                    className={classes.sellerLink}
                                    style={
                                        isMobile
                                            ? {
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  width: 200,
                                                  lineHeight: 'inherit',
                                                  display: 'flex',
                                              }
                                            : {}
                                    }
                                >
                                    <BSCTypography
                                        label={seller}
                                        onClick={() => setLinkableSeller(userId, seller)}
                                        size14
                                        style={{fontWeight: 700}}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item style={isMobile ? {paddingBottom: 2, paddingRight: 16} : {paddingTop: 2, paddingRight: 16}}>
                            Package {index} of {total}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container className={classes.root}>
                        {CartUserNormalizer.getCartItems(groupItem).map((cartItem, index) => {
                            return (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={12}
                                    className={classes.nestedGrid}
                                    key={cartItem.id}
                                    spacing={isMobile ? 1 : 2}
                                    style={isMobile ? {marginLeft: 4, paddingRight: 8} : {marginLeft: 0, paddingRight: 0}}
                                >
                                    <Grid item xs={12} sm={5} md={4} lg={4}>
                                        {index === 0 && (
                                            <SellerSummaryCart
                                                noOfItems={groupItem.noOfItems}
                                                price={groupItem.price}
                                                shippingCost={groupItem.shippingCost}
                                                subTotalPrice={groupItem.subTotalPrice}
                                                lowDollarAmount={lowDollarAmount}
                                                seller={seller}
                                                shippingTiers={sellerShippingTiers}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={7} md={8} lg={8}>
                                        <CheckoutCard
                                            key={cartItem.id}
                                            loading={loading}
                                            onQuantitySelect={onQuantitySelect}
                                            onDeleteCartItem={onDeleteCartItem}
                                            cartItem={cartItem}
                                            productInfo={cartItem.productItem.card}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default memo(CartDetail);
