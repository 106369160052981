import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import classNames from 'classnames';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {CartRequestActionEnum} from '../../../../client/openapitools/marketplace';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import useReduxCart from '../../../../features/hooks/redux/cart/useReduxCart';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import {RootState} from '../../../../redux/reducers';
import CartDetails from './cartDetails';
import MyCart from './myCart';
import SignInConfirmationModal from '../../../../bscFooter/SignInConfirmationModal';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    root: theme.containerWraps.page,
    rootAndroid: {
        height: 'calc(100vh - 133px)',
        overflow: 'hidden',
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    list: {
        [theme.breakpoints.down('sm')]: {
            marginTop: -theme.spacing(3),
        },
    },
    pwaListiOS: {
        [theme.breakpoints.down('sm')]: {
            marginTop: -theme.spacing(3),
        },
        maxHeight: 'calc(100vh - 510px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    pwaListAndroid: {
        [theme.breakpoints.down('sm')]: {
            marginTop: -theme.spacing(3),
        },
        maxHeight: 'calc(100vh - 440px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

function EditCart() {
    const classes = useStyles();
    const {instance} = useMsal();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {cartState, deleteCart, updateCart, getCart} = useReduxCart();
    const {getCartSessionId} = useReduxUser();
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isDesktop} = useScreenSnap();
    const [modalOpen, toggleModalOpen] = useState(false);
    const itemsLoading = cartState.deleteCartLoading || cartState.updateCartLoading;
    const loadingReasons = {
        // ['Getting Updated Cart Details']: loading,
        ['Modifying Cart Details']: itemsLoading,
    };

    const handleCheckout = () => {
        if (localStorage.getItem('accessToken') !== null) {
            history.push('/cart/checkout');
        } else {
            toggleModalOpen(true);
        }
    };

    const handleModalClose = e => {
        toggleModalOpen(false);
        if (e === 0) {
            history.push('/cart/checkout');
            authFunc.loginPWA();
        }
        if (e === 1) {
            history.push('/cart/checkout');
            authFunc.signUpPWA();
        }
    };

    const lowDollarAmount = process.env.REACT_APP_MINIMUM_PURCHASE_SINGLE_SELLER;

    const handleDeleteCartItem = useCallback(
        cartId => {
            console.log('>>>', cartId, getCartSessionId());
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                deleteCart({
                                    id: cartId,
                                    cartSessionId: getCartSessionId(),
                                    apiConfig,
                                    action: CartRequestActionEnum.Delete,
                                });
                            } catch (error) {
                                console.error('Error deleting cart', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'Delete cart',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            } else {
                try {
                    deleteCart({
                        id: cartId,
                        cartSessionId: getCartSessionId(),
                        apiAuthConfig,
                        action: CartRequestActionEnum.Delete,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } catch (error) {
                    console.error('Error deleting cart', error);
                    addToast({
                        severity: 'error',
                        message: 'An error has occurred.',
                        contextKey: 'Delete cart',
                    });
                }
            }
        },
        [deleteCart]
    );
    const handleQuantitySelect = useCallback(
        (quantity, cartItem) => {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                updateCart({
                                    id: cartItem.id,
                                    cartSessionId: cartItem.sessionId,
                                    productId: cartItem.productItem.productId,
                                    quantity,
                                    apiConfig,
                                    action: CartRequestActionEnum.Update,
                                });
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 3000);
                            } catch (error) {
                                console.error('Error deleting cart', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'Delete cart',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            } else {
                try {
                    updateCart({
                        id: cartItem.id,
                        cartSessionId: cartItem.sessionId,
                        productId: cartItem.productItem.productId,
                        quantity,
                        apiAuthConfig,
                        action: CartRequestActionEnum.Update,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } catch (error) {
                    console.error('Error deleting cart', error);
                    addToast({
                        severity: 'error',
                        message: 'An error has occurred.',
                        contextKey: 'Delete cart',
                    });
                }
            }
        },
        [updateCart]
    );

    const [pwaOS, setPwaOS] = useState(null);
    const [isSmallDevice, setIsSmallDevice] = useState(false);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
            setIsSmallDevice(marketplaceState.isSmallDevice);
        }
    }, [marketplaceState]);

    useEffect(() => {
        getCart();
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
    }, []);

    return (
        <>
            <div className={pwaOS === 'Android' ? classes.rootAndroid : classes.root}>
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={12} md={4} style={isDesktop ? {order: 2} : {order: 1}}>
                        <BSCLoading loading={itemsLoading} loadingReasons={loadingReasons} gutterBottom />
                        <MyCart {...cartState} onOrderSubmit={handleCheckout} loading={itemsLoading} buttonLabel="Check Out" />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={isDesktop ? {order: 1} : {order: 2}}
                        className={classNames({
                            [classes.pwaListiOS]: pwaOS === 'iOS',
                            [classes.pwaListAndroid]: pwaOS === 'Android',
                            [classes.list]: pwaOS !== 'iOS' && pwaOS !== 'Android',
                        })}
                    >
                        <CartDetails
                            onQuantitySelect={handleQuantitySelect}
                            onDeleteCartItem={handleDeleteCartItem}
                            loading={itemsLoading}
                            userCart={cartState}
                            lowDollarAmount={lowDollarAmount}
                        />
                    </Grid>
                </Grid>
                {(pwaOS === 'iOS' || pwaOS === 'Android') && cartState.noOfItems > 0 && (
                    <Grid
                        container
                        spacing={2}
                        style={
                            pwaOS === 'iOS'
                                ? {
                                      position: 'fixed',
                                      left: 0,
                                      bottom: 0,
                                      height: 90,
                                      background: '#fff',
                                      padding: '4px 16px 12px 16px',
                                      borderTop: '2px solid #d5d5d5',
                                  }
                                : {
                                      position: 'fixed',
                                      left: 0,
                                      bottom: 0,
                                      height: 80,
                                      background: '#fff',
                                      padding: '4px 16px 12px 16px',
                                      zIndex: 99999,
                                  }
                        }
                    >
                        <Grid item xs={12} md={12}>
                            <BSCButton onClick={handleCheckout} disabled={!lowDollarAmount} fullWidth color="primary">
                                Checkout
                            </BSCButton>
                        </Grid>
                    </Grid>
                )}
            </div>
            <SignInConfirmationModal open={modalOpen} onClose={e => handleModalClose(e)} isSmallDevice={isSmallDevice} />
        </>
    );
}

export default EditCart;
