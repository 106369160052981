import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import ListIcon from '../../../../../public/img/list_alt_black_24dp.svg';
import SellIcon from '../../../../../public/img/sell_black_24dp.svg';
import StatsIcon from '../../../../../public/img/show_chart_black_24dp.svg';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';

const baseStyles = makeStyles(() => ({
    mobileNavWrapper: {
        padding: '0px 8px 4px',
    },
    optionsWrapper: {
        textAlign: 'center',
        borderBottom: 'solid 3px #DAE0E6',
        marginBottom: '8px',
        paddingTop: '8px !important',
    },
    optionsWrapperActive: {
        textAlign: 'center',
        borderBottom: 'solid 3px #41474D',
        marginBottom: '8px',
        paddingTop: '8px !important',
    },
    imageActive: {
        opacity: '1',
    },
    imageInactive: {
        opacity: '0.4',
    },
    imageFlipHorizontally: {
        transform: 'scaleX(-1)',
    },
    iconFont: {
        fontSize: '10px',
    },
    iconFontBold: {
        fontSize: '10px',
        fontWeight: 'bolder',
    },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProductPageMobileNav = () => {
    const dispatch = useDispatch();
    const isSellers = useRouteMatch('/sellers');
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const classes = baseStyles();
    const {isMobile, isBelowTablet} = useScreenSnap();

    const handleListingsClick = () => {
        dispatch({
            type: constants.PWA_SELL_YOUR_CARDS_ACTIVE_TAB,
            payload: {pwaSellYourCardsActiveTab: 0},
        });
        dispatch({type: constants.NAVIGATE_MOBILE_SELL});
    };

    const handleDetailsClick = () => {
        dispatch({
            type: constants.PWA_SELL_YOUR_CARDS_ACTIVE_TAB,
            payload: {pwaSellYourCardsActiveTab: 1},
        });
        dispatch({type: constants.NAVIGATE_MOBILE_LIST});
    };

    const handleLivePricesClick = () => {
        dispatch({
            type: constants.PWA_SELL_YOUR_CARDS_ACTIVE_TAB,
            payload: {pwaSellYourCardsActiveTab: 2},
        });
        dispatch({type: constants.NAVIGATE_MOBILE_HISTORY});
    };

    useEffect(() => {
        dispatch({type: constants.NAVIGATE_MOBILE_SELL});
    }, [dispatch]);

    return (
        <Grid container spacing={2} className={classes.mobileNavWrapper}>
            {(isMobile || isBelowTablet) && !isSellers && (
                <>
                    <Grid
                        item
                        xs={4}
                        className={marketplaceState.isMobileSellActive ? classes.optionsWrapperActive : classes.optionsWrapper}
                        onClick={handleListingsClick}
                    >
                        <div className={classes.imageFlipHorizontally}>
                            <img
                                src={SellIcon}
                                className={marketplaceState.isMobileSellActive ? classes.imageActive : classes.imageInactive}
                                alt="Listings"
                            />
                        </div>
                        <BSCTypography
                            variant="body2"
                            align="center"
                            className={marketplaceState.isMobileSellActive ? classes.iconFontBold : classes.iconFont}
                            label="Listings"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={marketplaceState.isMobileListActive ? classes.optionsWrapperActive : classes.optionsWrapper}
                        onClick={handleDetailsClick}
                    >
                        <img
                            src={ListIcon}
                            className={marketplaceState.isMobileListActive ? classes.imageActive : classes.imageInactive}
                            alt="Details"
                        />
                        <BSCTypography
                            variant="body2"
                            align="center"
                            className={marketplaceState.isMobileListActive ? classes.iconFontBold : classes.iconFont}
                            label="Details"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={marketplaceState.isMobileHistoryActive ? classes.optionsWrapperActive : classes.optionsWrapper}
                        onClick={handleLivePricesClick}
                    >
                        <img
                            src={StatsIcon}
                            className={marketplaceState.isMobileHistoryActive ? classes.imageActive : classes.imageInactive}
                            alt="Past Sales"
                        />
                        <BSCTypography
                            variant="body2"
                            align="center"
                            className={marketplaceState.isMobileHistoryActive ? classes.iconFontBold : classes.iconFont}
                            label="Past Sales"
                        />
                    </Grid>
                </>
            )}
            {(isMobile || isBelowTablet) && isSellers && (
                <>
                    <Grid
                        item
                        xs={4}
                        className={marketplaceState.pwaSellYourCardsActiveTab === 0 ? classes.optionsWrapperActive : classes.optionsWrapper}
                        onClick={handleListingsClick}
                    >
                        <div className={classes.imageFlipHorizontally}>
                            <img
                                src={SellIcon}
                                className={marketplaceState.pwaSellYourCardsActiveTab === 0 ? classes.imageActive : classes.imageInactive}
                                alt="Listings"
                            />
                        </div>
                        <BSCTypography
                            variant="body2"
                            align="center"
                            className={marketplaceState.pwaSellYourCardsActiveTab === 0 ? classes.iconFontBold : classes.iconFont}
                            label="Listings"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={marketplaceState.pwaSellYourCardsActiveTab === 1 ? classes.optionsWrapperActive : classes.optionsWrapper}
                        onClick={handleDetailsClick}
                    >
                        <img
                            src={ListIcon}
                            className={marketplaceState.pwaSellYourCardsActiveTab === 1 ? classes.imageActive : classes.imageInactive}
                            alt="Details"
                        />
                        <BSCTypography
                            variant="body2"
                            align="center"
                            className={marketplaceState.pwaSellYourCardsActiveTab === 1 ? classes.iconFontBold : classes.iconFont}
                            label="Details"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={marketplaceState.pwaSellYourCardsActiveTab === 2 ? classes.optionsWrapperActive : classes.optionsWrapper}
                        onClick={handleLivePricesClick}
                    >
                        <img
                            src={StatsIcon}
                            className={marketplaceState.pwaSellYourCardsActiveTab === 2 ? classes.imageActive : classes.imageInactive}
                            alt="Past Sales"
                        />
                        <BSCTypography
                            variant="body2"
                            align="center"
                            className={marketplaceState.pwaSellYourCardsActiveTab === 2 ? classes.iconFontBold : classes.iconFont}
                            label={isSellers ? 'Live Prices' : 'Past Sales'}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default ProductPageMobileNav;
