import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch, useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCTab from '../../../../features/common/bscTab/bscTab';
import BSCTabs from '../../../../features/common/bscTabs/bscTabs';
import {RootState} from '../../../../redux/reducers';
import BePro from './tabs/bePro';
import ContactUs from './tabs/contactUs/ContactUs';
import Faq from './tabs/faq';
import Feedback from './tabs/feedback';
import Profile from './tabs/profile';

const tabEnums = {
    profile: {path: 'profile', key: 0},
    feedback: {path: 'feedback', key: 1},
    faq: {path: 'faq', key: 2},
    bepro: {path: 'bepro', key: 3},
};

const useStyles = makeStyles((theme: Theme) => ({
    cap: {
        width: '100%',
        overflowX: 'auto',
    },
    rootPWA: {
        height: 'var(--pwaAvailableSpace)',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    tabWrapper: {
        minWidth: 1200,
        [theme.breakpoints.down('xs')]: {
            minWidth: 323,
        },
        marginBottom: theme.spacing(2),
    },
    tabItem: {
        fontSize: theme.typography.body2.fontSize,
    },
}));

function SellerDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const {selectedTab}: {selectedTab: string} = useParams();
    const activeTab = tabEnums?.[selectedTab.toLowerCase()]?.key ?? null;
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [pwaOS, setPwaOS] = useState(null);

    useEffect(() => {
        if (!activeTab && marketplaceState.pwaSettings.pwaOS === '') {
            history.push('/sellers/dashboard/profile');
        }
    }, [activeTab, history, marketplaceState.pwaSettings.pwaOS]);

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
                if (marketplaceState.pwaSettings.pwaOS === 'iOS') {
                    document.documentElement.style.setProperty(
                        '--pwaAvailableSpace',
                        `calc(100vh - ${marketplaceState.pwaReservedSpace}px)`
                    );
                }
                if (marketplaceState.pwaSettings.pwaOS === 'Android') {
                    const pwaReservedSpaceAndroid = marketplaceState.pwaReservedSpace + 30;
                    document.documentElement.style.setProperty('--pwaAvailableSpace', `calc(100vh - ${pwaReservedSpaceAndroid}px)`);
                }
            }
        }
    }, [marketplaceState]);

    const handleTabChange = useCallback(
        (newTabKey: number) => {
            let newActiveTab = tabEnums.profile.path;
            for (const tabKey in tabEnums) {
                const item = tabEnums[tabKey];
                newActiveTab = item.key === newTabKey ? item.path : newActiveTab;
            }
            history.push(`/sellers/dashboard/${newActiveTab}`);
        },
        [history]
    );

    return (
        <BSCContainer variant="page" className={pwaOS === 'iOS' || pwaOS === 'Android' ? classes.rootPWA : null}>
            {pwaOS === null && (
                <div className={classes.cap}>
                    <div className={classes.tabWrapper}>
                        <BSCTabs activeTab={activeTab} onTabChange={handleTabChange} variant="fullWidth">
                            <BSCTab label="Seller Profile" value={tabEnums.profile.key} />
                            <BSCTab label="Feedback" value={tabEnums.feedback.key} />
                            <BSCTab label="FAQ" value={tabEnums.faq.key} />
                            {/* <BSCTab label="Become a pro!" value={tabEnums.bepro.key} /> */}
                        </BSCTabs>
                    </div>
                </div>
            )}
            <Switch>
                <Redirect push from="/sellers/dashboard/" to="/sellers/dashboard/profile/" exact />
                <Route path={'/sellers/dashboard/profile'}>
                    <Profile />
                </Route>
                <Route exact path={'/sellers/dashboard/feedback'}>
                    <Feedback />
                </Route>
                <Route exact path={'/sellers/dashboard/faq'}>
                    <Faq />
                </Route>
                <Route exact path={'/sellers/dashboard/bepro'}>
                    <BePro />
                </Route>
                <Route exact path={'/sellers/dashboard/support'}>
                    <ContactUs />
                </Route>
            </Switch>
        </BSCContainer>
    );
}

export default SellerDashboard;
