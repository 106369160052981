/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles, Theme} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import {forwardRef, KeyboardEvent, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import {RootState} from '../../../redux/reducers';
import BSCTextField, {BSCTextFieldProps} from '../bscTextField/bscTextField';
import useScreenSnap from '../../../packages/core/src/hooks/useScreenSnap';

const useStyles = makeStyles((theme: Theme) => ({
    searchInput: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            //   width: 'auto',
            width: 'calc(100% - 20px)',
            padding: '5px 10px 8px 10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
        },
    },
    pwaSearchInput: {
        display: 'flex',
        flexGrow: 1,
        width: 'calc(100vw *.7) !important',
        alignItems: 'center',
        padding: '8px 10px 8px 10px',
        '& input': {
            padding: theme.spacing(1.5, 1.25),
            fontSize: '14px !important',
        },
    },
    pwaSearchInputTablet: {
        flexGrow: 1,
        width: '600px !important',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '8px 10px 8px 10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
            fontSize: '14px !important',
        },
    },
    pwaSearchInputWide: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            margin: '2px',
            padding: '8px 6px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
            fontSize: '14px !important',
        },
    },
    pwaSearchResults: {
        display: 'flex',
        flexGrow: 1,
        width: 'calc(100vw * .6) !important',
        alignItems: 'center',
        padding: '8px 0px',
        '& input': {
            padding: theme.spacing(1.5, 1.25),
            fontSize: '14px !important',
        },
    },
    pwaSearchResultsTablet: {
        flexGrow: 1,
        width: '500px !important',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '8px 0px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
        },
    },
    pwaStorefrontSearch: {
        flexGrow: 1,
        width: '330px !important',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '330px !important',
            padding: '8px 6px',
        },
        '& input': {
            padding: theme.spacing(1.5, 1.25),
            fontSize: '14px !important',
        },
    },
    pwaStorefrontResults: {
        flexGrow: 1,
        width: 'calc(100vw * .9) !important',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 6px',
        '& input': {
            padding: theme.spacing(1.5, 1.25),
            fontSize: '14px !important',
        },
    },
    searchInputStyleProp: {
        background: theme.palette.common.white,
        borderRadius: 4,
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('md')]: {
            //   width: 'auto',
            width: '100%',
        },
    },
    endAdornmentInput: {
        //position: 'absolute',
        right: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            right: theme.spacing(0),
        },
    },
    endAdormentClick: {
        cursor: 'pointer',
    },
}));

interface BSCSearchFieldProps extends BSCTextFieldProps {
    onEnterDown?: (keyCode: string) => void;
    loading: boolean;
    onClear?: () => void;
}
const BSCSearchField = forwardRef<HTMLDivElement, BSCSearchFieldProps>(
    ({inputKey = null, onEnterDown, loading, formStateValidations = null, onClear, value, ...otherProps}: BSCSearchFieldProps, ref) => {
        const classes = useStyles();
        const {isTablet} = useScreenSnap();
        const isPWASearch = useRouteMatch('/pwa/dashboard/search');
        const isPWAProductListing = useRouteMatch('/product');
        const isPWASearchResults = useRouteMatch('/search');
        const marketplaceState = useSelector((state: RootState) => state.marketplace);
        const handleKeyDown = useCallback(
            (e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                    onEnterDown?.('Enter');
                }
            },
            [onEnterDown]
        );

        const [pwaOS, setPwaOS] = useState(null);
        const [isStorefrontPresent, setIsStorefrontPresent] = useState(false);
        const hasAutoFocus = isPWASearch || isPWASearch;
        useEffect(() => {
            if (marketplaceState) {
                if (marketplaceState.pwaSettings.pwaOS !== '') {
                    setPwaOS(marketplaceState.pwaSettings.pwaOS);
                }
                if (marketplaceState.sellerStorefront['sellerId'] !== undefined) {
                    setIsStorefrontPresent(true);
                }
            }
        }, [marketplaceState]);

        return (
            <BSCTextField
                {...otherProps}
                ref={ref}
                onKeyDown={handleKeyDown}
                value={value}
                className={classNames({
                    [classes.pwaSearchInput]: pwaOS === 'iOS' || pwaOS === 'Android',
                    [classes.pwaSearchInputWide]:
                        (pwaOS === 'iOS' || pwaOS === 'Android') && !isPWASearch && !isPWASearchResults && !isStorefrontPresent,
                    [classes.pwaSearchInput]: (pwaOS === 'iOS' || pwaOS === 'Android') && isPWASearch,
                    [classes.pwaSearchInputTablet]: (pwaOS === 'iOS' || pwaOS === 'Android') && isPWASearch && isTablet,
                    [classes.pwaSearchResults]: (pwaOS === 'iOS' || pwaOS === 'Android') && isPWASearchResults,
                    [classes.pwaSearchResultsTablet]: (pwaOS === 'iOS' || pwaOS === 'Android') && isPWASearchResults && isTablet,
                    [classes.pwaStorefrontSearch]: (pwaOS === 'iOS' || pwaOS === 'Android') && isPWASearch && isStorefrontPresent,
                    [classes.pwaStorefrontResults]: (pwaOS === 'iOS' || pwaOS === 'Android') && isPWASearchResults && isStorefrontPresent,
                    [classes.searchInput]: pwaOS === null,
                })}
                InputProps={{
                    autoFocus: hasAutoFocus,
                    className: classes.searchInputStyleProp,
                    startAdornment: (
                        <>
                            {loading && <CircularProgress className={classes.endAdornmentInput} size={20} color="primary" />}
                            {!loading && (
                                <SearchIcon
                                    className={classes.endAdornmentInput}
                                    onClick={() => {
                                        onEnterDown?.('Enter');
                                    }}
                                />
                            )}
                        </>
                    ),
                    endAdornment: (
                        <>
                            {loading && <CircularProgress className={classes.endAdornmentInput} size={20} color="primary" />}
                            {!loading && value?.length > 0 && (
                                <CloseIcon onClick={onClear} className={(classes.endAdornmentInput, classes.endAdormentClick)}></CloseIcon>
                            )}
                        </>
                    ),
                }}
                inputKey={inputKey || ''}
                formStateValidations={formStateValidations}
            />
        );
    }
);

export default BSCSearchField;
