import * as constants from '../../shared/constants';
import React, {useState, useCallback, useEffect} from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import {Link, useHistory} from 'react-router-dom';
import {Menu, MenuItem} from '@material-ui/core';
import BSCButton from '../common/bscButton/bscButton';
import {Skeleton} from '@material-ui/lab';
import BSCTypography from '../common/bscTypography/bscTypography';
import {useAuthFunctions, useAuthState} from '../../AuthContext';
import useReduxUser from '../hooks/redux/user/useReduxUser';
import BSCDivider from '../common/bscDivider/bscDivider';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux/reducers';
import {Configuration, ConversationApi} from '../../client/openapitools/common';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../authConfig';
import useReduxToast from '../hooks/redux/toast/useReduxToast';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontSize: '26pt', //theme.typography.subtitle1.fontSize,
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.body2.fontSize,
        },
        cursor: 'pointer',
    },
    sellersPortal: {
        textDecoration: 'none',
    },
    sellersLinkPortal: {
        textDecoration: 'none',
        height: 60,
        textTransform: 'inherit',
        '& span': {
            marginRight: theme.spacing(0.5),
        },
    },
    menuItemCenter: {
        justifyContent: 'center',
        height: 60,
    },
    menuPadding: {
        paddingRight: 17,
    },
    ddlPading: {
        margin: 'auto',
    },
    welcomeText: {
        fontSize: '12px',
        color: 'white',
        textTransform: 'capitalize',
    },
    font14: {
        fontSize: '14px',
    },
    messagesBadge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 8,
        left: -2,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    messagesBadgeHidden: {
        display: 'none',
    },
    menuMessagesBadge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 7,
        right: 16,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    menuStyle: {
        '& ul': {
            padding: '0 !important',
        },
    },
}));

export const SignInButton = ({userMenuLoading, userMenu, ...otherProps}) => {
    const classes = useStyles();
    const {instance, inProgress} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const handleLogout = () => {
        authFunc.logout();
    };

    const handleMenuOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenMenu(event.currentTarget);
        },
        [setOpenMenu]
    );

    const handleMenuOpenHover = useCallback(
        event => {
            setOpenMenu(event.currentTarget);
        },
        [setOpenMenu]
    );

    const handleMenuClose = useCallback(() => {
        setTimeout(() => {
            setOpenMenu(null);
        }, 2);
    }, [setOpenMenu]);

    const handleLogin = () => {
        authFunc.login();
    };
    const {userState, userRole} = useReduxUser();

    const [buyerMessagesCount, setBuyerMessagesCount] = useState(0);
    useEffect(() => {
        if (marketplaceState) {
            setBuyerMessagesCount(marketplaceState.buyerMessagesCount);
        }
    }, [marketplaceState]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        const conversationApi = new ConversationApi(apiConfig);
                        conversationApi.getConversationsForBuyer().then(res => {
                            const messagesData = res?.data.results;
                            if (messagesData !== null) {
                                const unreadMessages = messagesData.filter(msg => msg.read === false);
                                let tempBuyersMessageCount = 0;
                                if (unreadMessages.length > 0) {
                                    tempBuyersMessageCount = unreadMessages.length;
                                }
                                setBuyerMessagesCount(tempBuyersMessageCount);
                                dispatch({
                                    type: constants.BUYER_MESSAGES_COUNT,
                                    payload: {buyerMessagesCount: tempBuyersMessageCount},
                                });
                            }
                        });
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance, inProgress]);

    return (
        <>
            {inProgress === 'none' && (
                <>
                    <AuthenticatedTemplate>
                        <BSCButton
                            {...otherProps}
                            className={classes.root}
                            style={otherProps.style}
                            align="center"
                            variant="text"
                            onClick={handleMenuOpen}
                            onMouseEnter={handleMenuOpenHover}
                        >
                            <div className={buyerMessagesCount !== 0 ? classes.messagesBadge : classes.messagesBadgeHidden}>
                                {buyerMessagesCount}
                            </div>
                            <PersonOutlineIcon className={classes.root} />

                            {userState && (
                                <div className={classes.menuPadding}>
                                    <BSCTypography label="Welcome back," className={classes.welcomeText} />
                                    <BSCTypography
                                        label={userState?.firstName ? userState?.firstName : ''}
                                        className={classes.welcomeText}
                                    />
                                </div>
                            )}
                        </BSCButton>
                        <Menu
                            id="simple-menu"
                            className={classes.menuStyle}
                            anchorEl={openMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                            keepMounted
                            open={Boolean(openMenu)}
                            onClose={handleMenuClose}
                        >
                            <div onMouseLeave={handleMenuClose}>
                                {userMenuLoading && <Skeleton />}
                                {!userMenuLoading && userMenu && (
                                    <>
                                        {userMenu.dashboard.map(x => (
                                            <span
                                                key={x['title']}
                                                onClick={() => {
                                                    handleMenuClose();
                                                    history.push({
                                                        pathname: x['url'],
                                                    });
                                                }}
                                            >
                                                <MenuItem onClick={handleMenuClose} className={classes.menuItemCenter}>
                                                    <span
                                                        style={x['title'] === 'Messages' ? {display: 'flex'} : {display: 'none'}}
                                                        className={
                                                            buyerMessagesCount !== 0
                                                                ? classes.menuMessagesBadge
                                                                : classes.messagesBadgeHidden
                                                        }
                                                    >
                                                        {buyerMessagesCount !== 0 ? buyerMessagesCount : ''}
                                                    </span>
                                                    <BSCTypography
                                                        align="center"
                                                        variant="body2"
                                                        className={classes.font14}
                                                        label={x['title']}
                                                    />
                                                </MenuItem>
                                                <BSCDivider style={{margin: 0}} />
                                            </span>
                                        ))}
                                        <MenuItem
                                            key={'sellersLocker'}
                                            className={classes.menuItemCenter}
                                            to={userRole === '1' ? '/sellers/signup' : '/sellers'}
                                            component={Link}
                                        >
                                            <BSCButton
                                                variant="text"
                                                align="center"
                                                className={classes.sellersLinkPortal}
                                                startIcon={<StorefrontIcon color="primary" />}
                                            >
                                                <BSCTypography
                                                    variant="body2"
                                                    label="Seller's Locker"
                                                    className={classes.font14}
                                                ></BSCTypography>
                                            </BSCButton>
                                        </MenuItem>
                                        <BSCDivider style={{margin: 0}} />
                                        <MenuItem className={classes.menuItemCenter} onClick={handleLogout}>
                                            <BSCButton
                                                variant="text"
                                                align="center"
                                                className={classes.sellersLinkPortal}
                                                startIcon={<ExitToAppSharpIcon color="primary" />}
                                            >
                                                <BSCTypography
                                                    variant="body2"
                                                    align="left"
                                                    label="Sign Out"
                                                    className={classes.font14}
                                                ></BSCTypography>
                                            </BSCButton>
                                        </MenuItem>
                                    </>
                                )}
                            </div>
                        </Menu>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <BSCButton
                            {...otherProps}
                            style={otherProps.style}
                            color={otherProps.color ? otherProps.color : 'primary'}
                            onClick={handleLogin}
                        >
                            <BSCTypography variant={otherProps.variant} label="Sign in" {...otherProps}></BSCTypography>
                        </BSCButton>
                    </UnauthenticatedTemplate>
                </>
            )}
        </>
    );
};
