import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Configuration} from '../../../../client/openapitools/common';
import {CartRequest} from '../../../../client/openapitools/marketplace';
import {RootState} from '../../../../redux/reducers';
import {addToCart, deleteCart, deleteCartAll, getCart, mergeCart, mergeUserCart, updateCart} from '../../../../redux/slices/cart.slice';
import useReduxUser from '../user/useReduxUser';

function useReduxCart() {
    const dispatch = useDispatch();
    const cartState = useSelector((s: RootState) => s.cart);
    const {sessionId} = useReduxUser();

    const addToCartWrapper = useCallback(
        (cart: CartRequest, apiConfig: Configuration) => {
            return dispatch(addToCart(cart, apiConfig));
        },
        [dispatch]
    );

    const updateCartWrapper = useCallback(
        (cart: CartRequest, apiConfig: Configuration) => {
            return dispatch(updateCart(cart, apiConfig));
        },
        [dispatch]
    );

    const deleteCartWrapper = useCallback(
        (cart: CartRequest, apiConfig: Configuration) => {
            return dispatch(deleteCart(cart, apiConfig));
        },
        [dispatch]
    );

    const deleteCartAllWrapper = useCallback(
        (cart: CartRequest, apiConfig: Configuration) => {
            return dispatch(deleteCartAll(cart, apiConfig));
        },
        [dispatch]
    );
    const mergeCartWrapper = useCallback(
        (cart: CartRequest, apiConfig: Configuration) => {
            return dispatch(mergeCart(cart, apiConfig));
        },
        [dispatch]
    );
    const mergeUserCartWrapper = useCallback(
        (cart: CartRequest, apiConfig: Configuration) => {
            return dispatch(mergeUserCart(cart, apiConfig));
        },
        [dispatch]
    );

    const getCartWrapper = useCallback(
        (isAuth = false) => {
            let isSession;
            if (isAuth) {
                isSession = null;
            } else {
                // isSession = localStorage.getItem('deviceId') ?? sessionId;
                isSession = sessionId;
            }
            return dispatch(getCart(isSession));
        },
        [dispatch]
    );

    return {
        cartState,
        cartActionLoading:
            cartState.addToCartLoading || cartState.deleteCartLoading || cartState.mergeCartLoading || cartState.updateCartLoading,
        addCart: addToCartWrapper,
        updateCart: updateCartWrapper,
        deleteCart: deleteCartWrapper,
        deleteCartAll: deleteCartAllWrapper,
        mergeCart: mergeCartWrapper,
        mergeUserCart: mergeUserCartWrapper,
        getCart: getCartWrapper,
    };
}

export default useReduxCart;
