// import {Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, MobileStepper, Theme} from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Grid, makeStyles, MobileStepper, Theme} from '@material-ui/core';
import React, {useState} from 'react';
import {useCardNormalizer} from '../../../../../client/common/card/cardNormalizer';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import {Card} from '../../../../../client/openapitools/seller';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BSCImage from '../../../../../features/common/bscImage/bscImage';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import ProductPageMobileNav from '../../../../marketplace/pages/productPage/productPageMobileNav';
import './sellYourCard.scss';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 0,
        boxShadow: 'none',
    },
    main: {
        padding: theme.spacing(2),
    },
    mainPWA: {
        padding: theme.spacing(2),
        paddingBottom: 0,
    },
    image: {
        width: 226,
        height: 226,
        objectFit: 'contain',
        margin: theme.spacing(0, 'auto'),
        display: 'block',
        filter: 'drop-shadow(0px 2.7409px 2.7409px rgba(0, 0, 0, 0.25))',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh* .25)',
        },
    },

    divider: {
        width: '100%',
    },
    field: {
        padding: theme.spacing(0.5, 1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    action: {
        padding: theme.spacing(1, 2),
    },
    dotActive: {
        background: theme.palette.primary.main,
    },
    stepper: {
        bottom: '0px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        zIndex: 0,
        justifyContent: 'center',
        paddingBottom: '16px',
    },
    stepperMobile: {
        bottom: '0px',
        position: 'inherit',
        transform: 'none',
        background: 'transparent',
        marginTop: '0',
        justifyContent: 'center',
        padding: '8px 0px 12px',
    },
    font16: {
        fontSize: '16px',
        fontWeight: 600,
    },
    font18: {
        fontSize: '18px',
        fontWeight: 600,
    },
    fontBlack: {
        color: 'black',
    },
    selectDimensions: {
        height: 34,
        paddingTop: 10,
    },
    selectEllipsis: {
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    accordionTitle: {
        flexGrow: 0,
    },
    padding: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '10px',
        },
    },
}));

interface SellYourCardSidebarProps {
    sellerResults: Card;
}

function SellYourCardSidebar({sellerResults}: SellYourCardSidebarProps) {
    const classes = useStyles();
    const {isMobile, isBelowTablet} = useScreenSnap();
    const [activeStep, setActiveStep] = useState(0);
    const [isProductImageFrontView, setProductImageFrontView] = useState(true);
    const normalizer = useCardNormalizer();
    const {isDesktop} = useScreenSnap();
    const flipCard = () => {
        if (isProductImageFrontView) {
            setProductImageFrontView(false);
            setActiveStep(1);
        } else {
            setProductImageFrontView(true);
            setActiveStep(0);
        }
    };

    return (
        <>
            {sellerResults && (
                <BSCCardBox className={classes.root}>
                    <div className={isDesktop ? classes.main : classes.mainPWA}>
                        {/* Stock Image Front is missing from seller api Card */}

                        <BSCImage
                            className={classes.image}
                            src={
                                isProductImageFrontView
                                    ? Normalizer.getS3ImagePath(sellerResults?.stockImgFront)
                                    : Normalizer.getS3ImagePath(sellerResults?.stockImgBack)
                            }
                        />
                        <MobileStepper
                            classes={{dotActive: classes.dotActive}}
                            className={isBelowTablet || isMobile ? classes.stepperMobile : classes.stepper}
                            activeStep={activeStep}
                            onClick={() => flipCard()}
                            steps={2}
                            variant="dots"
                        />

                        <BSCTypography
                            className={isMobile || isBelowTablet ? classes.font16 : classes.font18}
                            center
                            capitalize
                            label={normalizer.getPlayerName(sellerResults)}
                        />

                        <BSCDivider className={classes.divider} />

                        {(isMobile || isBelowTablet) && <ProductPageMobileNav />}

                        {!isMobile && !isBelowTablet && (
                            <div style={{maxWidth: 450, margin: 'auto'}}>
                                <SideCardDetails sellerResults={sellerResults} />
                            </div>
                        )}
                    </div>
                    {!isMobile && !isBelowTablet && (
                        <>
                            <BSCDivider noMargin={isMobile} style={isMobile ? {} : {width: '94%', margin: '22px auto 0px'}} />
                            <div className={classes.action}>
                                <BSCButton
                                    fullWidth
                                    color="secondary"
                                    onClick={() => window.open(`/product/${sellerResults.id}`, '_blank')}
                                >
                                    <BSCTypography label="View Live Prices"></BSCTypography>
                                </BSCButton>
                            </div>
                        </>
                    )}
                </BSCCardBox>
            )}
        </>
    );
}

function SideCardDetails({sellerResults}: SellYourCardSidebarProps) {
    const classes = useStyles();
    const normalizer = useCardNormalizer();

    return (
        <Grid container spacing={2} className={classes.padding}>
            <Grid item xs={4}>
                <BSCTypography className={classes.fontBlack} label="Set:" />
            </Grid>
            <Grid item xs={8}>
                <BSCTypography className={classes.fontBlack} label={sellerResults?.setName} />
            </Grid>
            <Grid item xs={4}>
                <BSCTypography className={classes.fontBlack} label="Variant:" />
            </Grid>
            <Grid item xs={8}>
                <BSCTypography className={classes.fontBlack} label={normalizer.getVariantAndVariantName(sellerResults)} />
            </Grid>
            <Grid item xs={4}>
                <BSCTypography className={classes.fontBlack} label="Card #:" />
            </Grid>
            <Grid item xs={8}>
                <BSCTypography className={classes.fontBlack} label={sellerResults?.cardNo} />
            </Grid>
            <Grid item xs={4}>
                <BSCTypography className={classes.fontBlack} variant="subtitle1" label="Team:" />
            </Grid>
            <Grid item xs={8}>
                <BSCTypography className={classes.fontBlack} variant="subtitle1" label={sellerResults?.teamName} />
            </Grid>
            <Grid item xs={4}>
                <BSCTypography className={classes.fontBlack} variant="subtitle1" label="Attribute(s):" />
            </Grid>
            <Grid item xs={8}>
                <BSCTypography
                    variant="subtitle1"
                    className={classes.fontBlack}
                    //Old version.  playerAttributeDesc disappeared and only playerAttribute uis present so lets not break things
                    //label={sellerResults?.playerAttributeDesc + '(' + sellerResults?.playerAttribute + ')'}
                    label={sellerResults?.playerAttribute}
                />
            </Grid>
        </Grid>
    );
}

export default SellYourCardSidebar;
